import React from 'react';
import { DateFormatter } from 'ui/utils/DateFormatter';
import { Link } from 'react-router-dom';
import FollowingButton from 'ui/utils/FollowingButton';
import './style.css';

interface ProfileItemProps {
  user: Record<string, any>;
  onClick: () => void;
}

const ProfileItem: React.FC<ProfileItemProps> = ({ user, onClick }) => {
  return(
    <div id="profile-item" className="d-flex flex-row align-items-center">
      <Link className="profile-item-link" to={`/profile-user/${user.id}`}>
        <div className="flex-grow-0">
          <div className="profile-image" style={{ backgroundImage: 'url('+user.profile_image+')' }} />
        </div>
        <div className="d-flex flex-column mx-2 flex-grow-1 profile-item-data-content">
          <div className="name">{user.firstname} {user.lastname}</div>
          <div className="type">{user.profile_type ? user.profile_type : user.role}{user.role}, {DateFormatter.personAge(user.birthdate)}</div>
        </div>
      </Link>
      <div className="flex-grow-1">
        <FollowingButton following={true} onClick={onClick} userId={user.id} />
      </div>
    </div>
  );
};
export default ProfileItem;
