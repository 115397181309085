import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from 'ui/components/screen/Header';
import About from 'ui/components/screen/About';
import Multimedia from 'ui/components/screen/Multimedia';
import ProfileHeader from 'ui/components/screen/ProfileHeader';
import ProfileMenu from 'ui/components/screen/ProfileMenu';
import Bio from 'ui/components/screen/Bio';
import Measures from 'ui/components/screen/Measures';
import Footer from 'ui/components/screen/Footer';
import { UserContext } from '../../UserProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const ProfileScreen: React.FC = () => {
  const { me } = useContext(UserContext);
  return(
    <div className="container-app">
      <div className="container-fluid content">
        <Header data={me} me={me} />
        <ProfileHeader data={me} screen='me' />
        <ProfileMenu profileId={me.id} profileType={me.profile_type} />
        <Row className="container-dashboard container-web">
          <Col>
            <Switch>
              <Route exact path="/" render={() => <About about={me.about_yourself} images={me.gallery_images}  /> } />
              <Route path='/profile/measures/:profileId' component={Measures} />
              <Route path='/profile/multimedia/:profileId' render={() => <Multimedia images={me.gallery_images} videos={me?.gallery_videos} /> } />
              <Route exact path='/profile/bio/:profileId' render={() => <Bio data={me.bio} />} />
            </Switch>
          </Col>
        </Row>
      </div>
      <Footer profileId={me.id} />
    </div>
  );
};

export default ProfileScreen;
