import React from 'react';
import './style.css';

interface MeasureItemProps {
  label: string,
  value: string;
  selected?: boolean;
  suffix?: string;
  onClick: () => void;
}

const MeasureItem: React.FC<MeasureItemProps> = ({ label, value, suffix, selected, onClick }) => {
  return (
    <a onClick={onClick} className="measure-item">
      <div className="mb-2">
        {!selected && <span className="fw-bold">{label}:</span>}
        {selected && <span className="fw-bold selected">{label}:</span>}
        {suffix && <span className="mx-1">{value} {suffix}</span>}
        {!suffix && <span className="mx-1">{value}</span>}
      </div>
    </a>
  );
};

export default MeasureItem;
