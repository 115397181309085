import React, { useRef, useState } from 'react';
import LineIcon from 'ui/theme/icons/LineIcon';
import './style.css';
import { Overlay, Tooltip } from 'react-bootstrap';

interface ShareItemProps {
  label: string;
  icon: string;
  link: string;
}

const ShareItemCopy: React.FC<ShareItemProps> = ({ label, icon, link }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const copyProfileLink = () => {
    navigator.clipboard.writeText(link);
    setShow(true);
    setTimeout(() => setShow(false), 1000);
  };

  return(
    <div className="my-3">
      <a ref={target} onClick={copyProfileLink} className="d-flex flex-row align-items-center share-item-link">
        <LineIcon name={icon} /><span className="mx-2 share-item-link-text">{label}</span>
      </a>
      <Overlay target={target.current} show={show} placement="left">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};
export default ShareItemCopy;
