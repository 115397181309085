import React from 'react';
import { ReactComponent as ChevronLeft } from './Lineicons/chevron-left.svg';
import { ReactComponent as ChevronDown } from './Lineicons/chevron-down.svg';
import { ReactComponent as Menu } from './Lineicons/menu.svg';
import { ReactComponent as UserStar } from './Lineicons/user-star.svg';
import { ReactComponent as TopRightArrowBox } from './Lineicons/top-right-arrow-box.svg';
import { ReactComponent as Share } from './Lineicons/share.svg';
import { ReactComponent as MapMarker } from './Lineicons/map-marker.svg';
import { ReactComponent as RulerAlt1 } from './Lineicons/ruler-alt-1.svg';
import { ReactComponent as Image } from './Lineicons/image.svg';
import { ReactComponent as Page } from './Lineicons/page.svg';
import { ReactComponent as Facebook } from './Lineicons/facebook-original.svg';
import { ReactComponent as Twitter } from './Lineicons/twitter.svg';
import { ReactComponent as Instagram } from './Lineicons/instagram.svg';
import { ReactComponent as Measure } from './Lineicons/measure-tape.svg';
import { ReactComponent as UserEdit } from './Lineicons/user-edit.svg';
import { ReactComponent as HeartFilled } from './Lineicons/heart-filled.svg';
import { ReactComponent as PowerSwitch } from './Lineicons/power-switch.svg';
import { ReactComponent as Close } from './Lineicons/close.svg';
import { ReactComponent as Circle } from './Lineicons/circle.svg';
import { ReactComponent as CheckCircle } from './Lineicons/check-circle.svg';
import { ReactComponent as CrossCircle } from './Lineicons/cross-circle.svg';
import { ReactComponent as Upload } from './Lineicons/upload.svg';
import { ReactComponent as CirclePlus } from './Lineicons/circle-plus.svg';
import { ReactComponent as Video } from './Lineicons/video-camera-alt-2.svg';
import { ReactComponent as Play } from './Lineicons/play.svg';
import { ReactComponent as Home } from './Lineicons/home.svg';
import { ReactComponent as Whatsapp } from './Lineicons/whatsapp.svg';
import { ReactComponent as Messenger } from './Lineicons/facebook-messenger.svg';
import { ReactComponent as Email } from './Lineicons/envelope.svg';
import { ReactComponent as Link } from './Lineicons/link.svg';
import { ReactComponent as Pdf } from './Lineicons/adobe.svg';
import { ReactComponent as Search } from './Lineicons/search-alt.svg';
import { ReactComponent as Certificate } from './Lineicons/certificate.svg';
import { ReactComponent as UserSearch } from './Lineicons/user-search.svg';
import { ThemeStatic } from 'ui/theme/colors';

const icons: Record<string, any> = {
  'chevron-left': ChevronLeft,
  'chevron-down': ChevronDown,
  menu: Menu,
  'user-star': UserStar,
  'top-right-arrow-box': TopRightArrowBox,
  share: Share,
  'map-marker': MapMarker,
  'ruler-alt-1': RulerAlt1,
  image: Image,
  page: Page,
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  measure: Measure,
  'user-edit': UserEdit,
  'heart-filled': HeartFilled,
  'power-switch': PowerSwitch,
  close: Close,
  circle: Circle,
  'check-circle': CheckCircle,
  'cross-circle': CrossCircle,
  upload: Upload,
  'circle-plus': CirclePlus,
  video: Video,
  play: Play,
  home: Home,
  whatsapp: Whatsapp,
  messenger: Messenger,
  email: Email,
  link: Link,
  pdf: Pdf,
  search: Search,
  certificate: Certificate,
  'user-search': UserSearch,
};

interface LineIconProps {
  name: string,
  size?: number,
  color?: string;
  style?: string;
}

const LineIcon: React.FC<LineIconProps> = ({ name, color, size, style }) => {
  const Icon = icons[name];
  return (
    <div className={style}>
      <Icon width={size || 20} height={size || 20} fill={color || ThemeStatic.gray} />
    </div>
  );
};

export default LineIcon;


