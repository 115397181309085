import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useQuery } from '@apollo/client';
import { QUERY_ME, QUERY_USER } from 'infrastructure/graphql/query';
import Header from 'ui/components/screen/Header';
import About from 'ui/components/screen/About';
import Multimedia from 'ui/components/screen/Multimedia';
import ProfileHeader from 'ui/components/screen/ProfileHeader';
import ProfileMenu from 'ui/components/screen/ProfileMenu';
import Bio from 'ui/components/screen/Bio';
import Measures from 'ui/components/screen/Measures';
import Footer from 'ui/components/screen/Footer';
import LoadingScreen from 'ui/components/LoadingScreen';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router';
import { UserContext } from '../UserProvider';
import './style.css';

const UserProfileScreen: React.FC = () => {
  const params = useParams<Record<string, any>>();
  const { me, setMe } = useContext(UserContext);
  const [loadingMe, setLoadingMe] = useState<boolean>(true);

  const { loading, data, error } = useQuery(QUERY_USER, { variables: { id: params.profileId }, fetchPolicy: 'network-only' });
  const { data: dataMe } = useQuery(QUERY_ME, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (dataMe){
      setMe(dataMe.me);
      setLoadingMe(false);
    }
  } , [dataMe]);

  if (loading || loadingMe) return <LoadingScreen />;
  if (error) return <span>ERROR OCURRED</span>;
  return(
    <div className="container-app">
      <div className="container-fluid content">
        <Header data={data.user} me={me} />
        <ProfileHeader data={data.user} guest screen='user' />
        <ProfileMenu profileId={data.user.id} user profileType={data.user.profile_type} />
        <Row className="container-dashboard container-web">
          <Col>
            <Switch>
              <Route exact path="/profile-user/:profileId" render={() => <About about={data.user.about_yourself} images={data.user.gallery_images}  /> } />
              <Route exact path='/profile-user/:profileId/measures' component={Measures} />
              <Route exact path='/profile-user/:profileId/multimedia' render={() => <Multimedia images={data.user.gallery_images} videos={data.user?.gallery_videos} /> } />
              <Route exact path='/profile-user/:profileId/bio' render={() => <Bio data={data.user.bio} />} />
            </Switch>
          </Col>
        </Row>
      </div>
      <Footer profileId={data.user.id} user />
    </div>
  );
};

export default UserProfileScreen;
