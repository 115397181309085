const SESSION_KEY: string = 'SU_STORAGE_SESSION_KEY';

export class SessionStorage {

  static store(key: string): void { sessionStorage.setItem(SESSION_KEY, key); }

  static get(): string | null { return sessionStorage.getItem(SESSION_KEY); }

  static delete(): void { sessionStorage.removeItem(SESSION_KEY); }

}
