import React from 'react';
import LineIcon from 'ui/theme/icons/LineIcon';
import { ThemeStatic } from 'ui/theme/colors';
import { Link } from 'react-router-dom';

interface MenuItemProps {
  label: string;
  icon: string;
  size: number;
  link: string;
}
const MenuItem: React.FC<MenuItemProps> = ({ label, icon, size, link }) => {
  return(
    <Link to={link} className='d-flex flex-row menu-item'>
      <LineIcon name={icon} size={size} color={ThemeStatic.gray} /><span>{label}</span>
    </Link>
  );
};
export default MenuItem;
