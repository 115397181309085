import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { useParams } from 'react-router';
import './style.css';

const ActivationScreen: React.FC = () => {
  // const params = useParams<Record<string, any>>();
  /*
  const { loading, data, error } = useQuery(QUERY_USER, { variables: { id: params.profileId }, fetchPolicy: 'network-only' });
  const { loading: loadingMe , data: dataMe, error: errorMe } = useQuery(QUERY_ME, { fetchPolicy: 'network-only' });

  if (loading || loadingMe) return <LoadingScreen />;
  if (error || errorMe) return <span>ERROR OCURRED</span>;
  return(
    <div className="container-app">
      <div className="container-fluid content">
        <Header data={data.user} me={dataMe.me} />
        <ProfileHeader data={data.user} guest screen='user' />
        <ProfileMenu profileId={data.user.id} user profileType={data.user.profile_type} />
        <Row className="container-dashboard container-web">
          <Col>
          </Col>
        </Row>
      </div>
      <Footer profileId={data.user.id} user />
    </div>
  );*/
  return (<></>);
};

export default ActivationScreen;
