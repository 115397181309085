import React from 'react';
import './style.css';

interface FormInputProps {
  type: string;
  value: string;
  onChange?: (event: any) => void;
  placeholder: string;
  dark?: boolean;
  error?: string;
  disabled?: boolean;
  onKeyDown?: (e: any) => void;
}

const FormInput: React.FC<FormInputProps> = ({ type, value, onChange, placeholder, dark, error, disabled, onKeyDown }) => {
  return(
    <div className='mt-2'>
      <input
        type={type}
        className={dark ? 'custom-text-input-dark' : 'custom-text-input'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      {error && <span className="form-input-error">{error}</span>}
    </div>
  );
};


export default FormInput;
