import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { MUTATION_SIGNIN } from 'infrastructure/graphql/mutation';
import LoginUI from './ui';
import { Validator } from 'ui/utils/Validator';
import { SessionStorage } from 'ui/utils/SessionStorage';

interface LoginProps {
  setToken: Dispatch<SetStateAction<string | null>>;
}

const Login: React.FC<LoginProps> = ({ setToken }) => {

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');

  const [signIn, statusMutation] = useMutation(MUTATION_SIGNIN, {
    async onCompleted ({ signIn }) {
      if (signIn) {
        SessionStorage.store(signIn.token);
        window.location.reload();
      }
    }
  });

  useEffect(() => { init(); }, []);

  const init = () => {
    if(SessionStorage.get()) {
      setToken(SessionStorage.get());
    }
  };

  const onKeydownPassword = (event: any) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      tryLogin();
    }
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value); };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => { setPassword(event.target.value); };

  const validateEmptyEmail = () => {
    if (Validator.emptyField(email)) { setEmailError('You must enter your email'); throw Error; } else { setEmailError(''); }
  };

  const validateEmptyPassword = () => {
    if (Validator.emptyField(password)) { setPasswordError('You must enter your password'); throw Error; } else { setPasswordError(''); }
  };

  const validateEmailFormat = () => {
    if (!Validator.validEmailFormat(email)) { setEmailError('Email has not valid format'); throw Error; } else { setEmailError(''); }
  };

  const login = async () => {
    await signIn({ variables: { email: email, password: password } });
  };

  const tryLogin = async () => {
    try {
      setServerError('');
      validateEmptyEmail();
      validateEmailFormat();
      validateEmptyPassword();
      await login();
    } catch (e) {
      setServerError(e.message);
    }
  };

  return(
    <LoginUI
      email={email}
      emailError={emailError}
      password={password}
      passwordError={passwordError}
      handleEmail={handleEmail}
      handlePassword={handlePassword}
      serverError={serverError}
      tryLogin={tryLogin}
      isLoading={statusMutation.loading}
      onKeyDownPassword={onKeydownPassword}
    />
  );
};

export default Login;
