import React, { useState, useEffect, useContext } from 'react';
import ProfileScreen from 'ui/MyProfile/ProfileScreen';
import SearchProfile from 'ui/UserProfile';
import Login from 'ui/MyProfile/Login';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from 'infrastructure/graphql/query';
import LoadingScreen from 'ui/components/LoadingScreen';
import { UserContext } from '../UserProvider';

const Profile: React.FC = () => {
  const params = useParams<Record<string, any>>();
  const [token, setToken] = useState<string | null>(null);
  const { loading, data } = useQuery(QUERY_ME, { fetchPolicy: 'network-only' });
  const { me, setMe } = useContext(UserContext);
  useEffect(() => {
    if (data){
      setMe(data.me);
    }
  } , [data]);
  if (!token) return (<Login setToken={setToken} />);
  if (loading || !me) return <LoadingScreen />;
  return (
    <>
      {(params.profileId) ? <SearchProfile /> : <ProfileScreen /> }
    </>
  );
};

export default Profile;
