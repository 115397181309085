import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from 'ui/components/form/PrimaryButton';
import FormInput from 'ui/components/form/FormInput';
import FormTextarea from 'ui/components/form/FormTextarea';
import { Validator } from 'ui/utils/Validator';
import { useMutation } from '@apollo/client';
import { MUTATION_SEND_CONTACT_EMAIL } from 'infrastructure/graphql/mutation';
import { useParams } from 'react-router';
import './style.css';

interface ContactModalProps {
  visibleContactModal: boolean;
  hideContactModal: () => void;
  name: string;
}

const ContactModal: React.FC<ContactModalProps> = ({ visibleContactModal, hideContactModal, name }) => {

  const params = useParams<Record<string, any>>();

  const [nameContact, setNameContact] = useState<string>('');
  const [phoneContact, setPhoneContact] = useState<string>('');
  const [emailContact, setEmailContact] = useState<string>('');
  const [showSendMessage, setShowSendMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const [emailContactError, setEmailContactError] = useState<string>('');
  const [phoneContactError, setPhoneContactError] = useState<string>('');
  const [nameContactError, setNameContactError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const [sendContactEmail] = useMutation(MUTATION_SEND_CONTACT_EMAIL, {
    async onCompleted ({ sendContactEmail }) {
      if (sendContactEmail) {
        console.log('Mail sended: ', sendContactEmail);
      }
    },
    async onError (error) {
      console.log('Mail sended: ', error);
    }
  });

  const validateEmptyNameContact = () => {
    if (Validator.emptyField(nameContact)) { setNameContactError('You must enter your name'); throw Error; } else { setNameContactError(''); }
  };

  const validateEmptyEmailContact = () => {
    if (Validator.emptyField(emailContact)) { setEmailContactError('You must enter your email'); throw Error; } else { setEmailContactError(''); }
  };

  const validateEmptyPhoneContact = () => {
    if (Validator.emptyField(phoneContact)) { setPhoneContactError('You must enter your phone'); throw Error; } else { setPhoneContactError(''); }
  };

  const validateEmailFormat = () => {
    if (!Validator.validEmailFormat(emailContact)) { setEmailContactError('Email has not valid format'); throw Error; } else { setEmailContactError(''); }
  };

  const validateEmptyMessage = () => {
    if (Validator.emptyField(message)) { setMessageError('You must enter your message'); throw Error; } else { setMessageError(''); }
  };


  const validate = async () => {
    try {
      validateEmptyNameContact();
      validateEmptyPhoneContact();
      validateEmptyEmailContact();
      validateEmailFormat();
      validateEmptyMessage();
      const variables = buildMessage();
      await sendContactEmail({ variables });
      setShowSendMessage(true);
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  const closeModal = () => {
    setTimeout(() => {
      hideContactModal();
      cleanAllFields();
      setShowSendMessage(false);
    }, 2000);
  };

  const cleanAllFields = () => {
    setNameContact('');
    setEmailContact('');
    setPhoneContact('');
    setMessage('');
  };


  const buildMessage = () => {
    return {
      id: params.profileId,
      message: message,
      name: nameContact,
      email: emailContact,
      phone: phoneContact
    };
  };

  const handleNameContact = (event: React.ChangeEvent<HTMLInputElement>) => { setNameContact(event.target.value); };

  const handlePhoneContact = (event: React.ChangeEvent<HTMLInputElement>) => { setPhoneContact(event.target.value); };

  const handleEmailContact = (event: React.ChangeEvent<HTMLInputElement>) => { setEmailContact(event.target.value); };

  const handleMessage = (event: React.ChangeEvent<HTMLInputElement>) => { setMessage(event.target.value); };

  return(
    <Modal id="contact-modal" show={visibleContactModal} onHide={hideContactModal}>
      <Modal.Header closeButton>
        <Modal.Title>Contact with {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showSendMessage && <div className='message-send'>Your message has been sent successfully</div>}
        <FormInput type="text" value={nameContact} onChange={handleNameContact} placeholder={'Name / Company'} dark error={nameContactError} />
        <FormInput type="text" value={phoneContact} onChange={handlePhoneContact} placeholder={'Phone'} dark error={phoneContactError} />
        <FormInput type="text" value={emailContact} onChange={handleEmailContact} placeholder={'Email'} dark error={emailContactError} />
        <FormTextarea value={message} onChange={handleMessage} placeholder={'Message'} error={messageError}  />
        <PrimaryButton style="contact-button" label="Send" loadingMessage="Sending..." onClick={validate} />
        <div className="contact-modal-terms-text">
          By continuing, you&apos;re confirming that you&apos;ve read and agree to our <strong>Terms and Conditions, Privacy Policy</strong> and <strong>Cookie Policy</strong>.
        </div>
      </Modal.Body>
    </Modal>

  );
};
export default ContactModal;
