import React, { useEffect } from 'react';
import { SessionStorage } from 'ui/utils/SessionStorage';
import { useHistory } from 'react-router';

const Logout: React.FC = () => {

  const history = useHistory();

  useEffect(() => {
    SessionStorage.delete();
    history.push('/');
    window.location.reload();
  }, []);

  return(<></>);
};

export default Logout;
