import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

interface FooterProps {
  profileId: string;
  user?: boolean;
}

const Footer: React.FC<FooterProps> = ({ profileId, user }) => {
  return(
    <div id="footer">
      <div className="d-flex flex-row">
        <Link className="footer-link" to={'/'}>About</Link>
        <Link className="footer-link" to={'/terms-and-conditions'}>Terms and Conditions</Link>
        <Link className="footer-link" to={'/privacy-policy'}>Privacy Policy</Link>
        <Link className="footer-link" to={'/cookie-policy'}>Cookie Policy</Link>
      </div>
      <div>&copy; 2022 Show United SL</div>
    </div>
  );
};


export default Footer;
