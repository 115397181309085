import React from 'react';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import './style.css';

interface PhotosProps {
  images: string[];
}

const getThumbnailSize = (image: string) => {
  const imageT = image.replace('.jpeg','_350x350.jpeg').replace('.png','_350x350.png').replace('.jpg','_350x350.jpg').replace('.svg','_350x350.svg');
  return imageT;
};

const Photos: React.FC<PhotosProps> = ({ images }) => {
  return(
    <Gallery shareButton={false}>
      {images.map((image: string, index: number) =>
        <Item
          key={index}
          original={image}
          thumbnail={getThumbnailSize(image)}
          width="768"
          height="768"
        >
          {({ ref, open }) => (
          // @ts-ignore
            <img className="photos-image" ref={ref} onClick={open} src={getThumbnailSize(image)} />
          )}
        </Item>
      )}
    </Gallery>
  );
};

export default Photos;
