import React  from 'react';
import { useState, createContext } from 'react';

const useValue = () => {
  const [me, setMe] = useState<any>();
  return {
    me,
    setMe
  };
};

const UserContext = createContext({} as ReturnType<typeof useValue>);

const UserProvider = (props: any) => {
  return (
    <UserContext.Provider value={useValue()}>
      {props.children}
    </UserContext.Provider>
  );
};
export { UserContext, UserProvider };
