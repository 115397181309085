import React from 'react';
import './style.css';
import { Col } from 'react-bootstrap';

interface BioProps {
  data: Record<string, any>;
}

const Bio: React.FC<BioProps> = ({ data }) => {
  return(
    <Col className="mt-2" style={{ whiteSpace: 'pre-wrap' }}>
      {data}
    </Col>
  );
};
export default Bio;
