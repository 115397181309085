import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';

interface FormTextareaProps {
  value: string;
  onChange: (event: any) => void;
  placeholder: string;
  error?: string;
}

const FormTextarea: React.FC<FormTextareaProps> = ({ value, onChange, placeholder, error }) => {
  return(
    <Row>
      <Col className='mt-2'>
        <textarea value={value} onChange={onChange} placeholder={placeholder} />
        {error && <span className="form-input-error">{error}</span>}
      </Col>
    </Row>
  );
};


export default FormTextarea;
