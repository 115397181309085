import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ShareItemHref from './components/ShareItem/ShareItemHref';
import ShareItemCopy from './components/ShareItem/ShareItemCopy';
import './style.css';

interface ContactModalProps {
  visibleShareModal: boolean;
  hideShareModal: () => void;
  data?: Record<string, any>;
}

const ShareModal: React.FC<ContactModalProps> = ({ visibleShareModal, hideShareModal, data }) => {
  const baseUrl = 'https://www.showunited.com/app/';
  const shareUrl = baseUrl + 'profile-user/'+ data!.id;
  const shareUrlPdf = 'https://s1.showunited.com/api/generate_profile_pdf/'+ data!.id;

  return(
    <Modal id="share-modal" show={visibleShareModal} onHide={hideShareModal}>
      <Modal.Header closeButton>
        <Modal.Title>Share on...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ShareItemHref label={'Share on Whatsapp'} icon={'whatsapp'} href={'whatsapp://send?text=Hi, take a look at this profile in ShowUnited: ' + shareUrl} />
        <ShareItemCopy label={'Share PDF link'} icon={'pdf'} link={shareUrlPdf}  />
        <ShareItemHref label={'Share on Facebook'} icon={'facebook'} href={'http://www.facebook.com/sharer.php?u=' + shareUrl} />
        <ShareItemHref label={'Share on Twitter'} icon={'twitter'} href={'http://twitter.com/share?text=Hi, take a look at this profile in ShowUnited: ' + shareUrl} />
        <ShareItemHref label={'Share by email'} icon={'email'} href={data ? 'mailto:' + data.email + '?Subject=Hi, take a look at this profile in ShowUnited' : ''} />
        <ShareItemCopy label={'Copy link'} icon={'link'} link={shareUrl} />
      </Modal.Body>
    </Modal>
  );
};
export default ShareModal;
