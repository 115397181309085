import React from 'react';
import { Col } from 'react-bootstrap';
import { Gallery, Item } from 'react-photoswipe-gallery';
import './style.css';

interface ImagesProps {
  images: string[];
}
const Images: React.FC<ImagesProps> = ({ images }) => {

  const loadStyles = (index: number) => {
    let styles = 'profile-image';
    if (index % 2 === 0)
      styles += ' mx-2';
    if (index > 3)
      styles += ' hide-xs';
    return styles;
  };

  const getThumbnailSize = (image: string) => {
    const imageT = image.replace('.jpeg','_350x350.jpeg').replace('.png','_350x350.png').replace('.jpg','_350x350.jpg').replace('.svg','_350x350.svg');
    return imageT;
  };

  const getImageWidth = (image: string) => {
    const img = new Image();
    img.src = image;
    return img.width;
  };

  const getImageHeight = (image: string) => {
    const img = new Image();
    img.src = image;
    return img.height;
  };

  return(
    <Col className="d-flex flex-row justify-content-center">
      <Gallery shareButton={false}>
        {images.map((image: string, index: number) =>
          <Item
            key={index}
            original={image}
            thumbnail={getThumbnailSize(image)}
            width={getImageWidth(image)}
            height={getImageHeight(image)}
          >
            {({ ref, open }) => (
              // @ts-ignore
              <img className={loadStyles(index)} ref={ref} onClick={open} src={getThumbnailSize(image)} />
            )}
          </Item>
        )}
      </Gallery>
      <div className="more-images">+10</div>
    </Col>
  );
};
export default Images;
