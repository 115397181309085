import React from 'react';
import LineIcon from 'ui/theme/icons/LineIcon';
import './style.css';

interface ShareItemProps {
  label: string,
  icon: string,
  href?: string;
}

const ShareItemHref: React.FC<ShareItemProps> = ({ label, icon, href }) => {
  return(
    <div className="my-3">
      <a href={href} className="d-flex flex-row align-items-center share-item-link">
        <LineIcon name={icon} /><span className="mx-2 share-item-link-text">{label}</span>
      </a>
    </div>
  );
};
export default ShareItemHref;
