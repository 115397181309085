import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './style.css';

interface VideoProps {
  src: string;
  image: Record<string, any>;
}

const Video: React.FC<VideoProps> = ({ src, image }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return(
    <>
      <a onClick={handleShow} className="justify-content-center align-items-center">
        <div className="video-thumbnail" style={{ backgroundImage: 'url(' + image.sm.replace('https://vod.dyntube.net','https://cdn.dyntube.net') + ')' }}></div>
        <i className="icon-play" />
      </a>
      <Modal className="video-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe src={src.replace('https://vod.dyntube.net','https://cdn.dyntube.net')} width="870" height="485" frameBorder="0" allowFullScreen></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Video;
