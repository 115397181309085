import React, { useState } from 'react';
import ForgotPasswordUI from './ui';
import { Validator } from 'ui/utils/Validator';
import { FetchResult, useMutation } from '@apollo/client';
import { MUTATION_SEND_EMAIL_RESET_PASSWORD } from 'infrastructure/graphql/mutation';

const ForgotPassword: React.FC = () => {

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [emailSended, setEmailSended] = useState<boolean>(false);
  const [sendResetEmail, { loading }] = useMutation(MUTATION_SEND_EMAIL_RESET_PASSWORD);

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value); };

  const validateEmptyEmail = () => {
    if (Validator.emptyField(email)) { setEmailError('You must enter your email'); throw Error; } else { setEmailError(''); }
  };

  async function sendEmail() {
    const variables = { email: email };
    const response = await sendResetEmail({ variables });
    validateResponse(response);
  }

  const validateResponse = (response: FetchResult<any>) => {
    if (!response.data.sendResetPassword.success)
      throw new Error('The email does not correspond to any user.');
  };

  const tryReset = async () => {
    try {
      validateEmptyEmail();
      await sendEmail();
      setEmailSended(true);
    } catch (e) {
      setServerError(e.message);
    }
  };

  return(
    <ForgotPasswordUI
      email={email}
      emailError={emailError}
      handleEmail={handleEmail}
      tryReset={tryReset}
      isLoading={loading}
      serverError={serverError}
      emailSended={emailSended}
    />
  );
};

export default ForgotPassword;
