import React, { useContext, useState, useEffect } from 'react';
import PrimaryButton from 'ui/components/form/PrimaryButton';
import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_FOLLOW_DATA_USER } from 'infrastructure/graphql/mutation';
import { UserContext } from '../../UserProvider';
import './style.css';

interface FollowingButtonProps {
  userId: string;
  onClick: () => void;
  following: boolean;
}

const FollowingButton: React.FC<FollowingButtonProps> = ({ following, userId, onClick }) => {
  const { me, setMe } = useContext(UserContext);
  const [isFollowing, setIsfollowing] = useState(following);
  const [updateFollowing] = useMutation(MUTATION_UPDATE_FOLLOW_DATA_USER);
  useEffect(()=> {
    const index = me.following.indexOf(userId);
    setIsfollowing(index > -1 ? true : false);
  },[]);
  const changeFollowState = async (f: boolean, userId: string) => {
    let auxUser = { ...me };
    let newFollowList = [...me.following];
    if (f) { newFollowList = unFollow(newFollowList, userId); }
    else{ newFollowList = follow(newFollowList, userId); }
    if (!following)
      setIsfollowing(!f);
    const variables = { following: newFollowList };
    await updateFollowing({ variables });
    auxUser.following = newFollowList;
    setMe(auxUser);
    onClick();
  };

  const unFollow = (newFollowList: string[], userId: string): string[] => {
    const index = newFollowList.indexOf(userId);
    if (index > -1) { newFollowList.splice(index, 1); }
    return newFollowList;
  };

  const follow = (newFollowList: string[], userId: string): string[] => {
    newFollowList.push(userId);
    return newFollowList;
  };

  return(<PrimaryButton large={'small'} style="mt-2" label={isFollowing ? 'Unfollow' : 'Follow'} loadingMessage="processing..." onClick={() => changeFollowState(isFollowing, userId)} alternativeButton={isFollowing} />
  );
};
export default FollowingButton;
