import React from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import Photos from './components/Photos';
import Videos from './components/Videos';
import './style.css';

interface MultimediaScreenProps {
  images: string[];
  videos: Record<string, any>[];
}
const Multimedia: React.FC<MultimediaScreenProps> = ({ images, videos }) => {
  return(
    <Col className="mt-2">
      <Tabs defaultActiveKey="photos" className="tabs mt-2">
        <Tab eventKey="photos" title="Photos">
          <Photos images={images} />
        </Tab>
        <Tab eventKey="videos" title="Videos">
          <Videos videos={videos} />
        </Tab>
      </Tabs>
    </Col>
  );
};
export default Multimedia;
