import gql from 'graphql-tag';

export const QUERY_ME = gql`
  query Me {
    me {
        id
        firstname
        lastname
        country
        role
        profile_type
        about_yourself
        birthdate
        gallery_images
        gallery_videos {
          video_id
          title
          hsl_url
          mp4_url
          status
          image {
            sm
            xs
          }
        }
        profile_image
        email
        phone
        gender
        categories
        sub_categories
        active
        company_name
        company_contact
        company_project
        company_description
        city_address
        supported_brands
        following
        bio
    }
  }
`;

export const QUERY_USER = gql`
  query User(
    $id: ID!
  ) {
    user(id: $id) {
        id
        bio
        firstname
        lastname
        profile_type
        email
        country
        role
        about_yourself
        birthdate
        gallery_images
        gallery_videos {
          video_id
          title
          hsl_url
          mp4_url
          status
          image {
            sm
            xs
          }
        }
        profile_image
    }
  }
`;

export const QUERY_USER_MEASURES = gql`
  query User(
    $id: ID!
  ) {
    user(id: $id) {
        id
        gender
        measurement {
            height
            weight
            hair_color
            high_bust
            full_bust
            waist
            high_hip
            full_hip
            ankle_to_hip
            ankle_to_knee
            ankle_girth
            knee_girth
            inseam
            shoe_size
        }
    }
  }
`;

export const QUERY_LIST_USERS = gql`
 query Users {
    users {
         id
         firstname
         lastname
         role
         birthdate
         country
         profile_image
    }
  }
`;

export const CATEGORIES = gql`
  query Categories {
    categories {
        id
        parent
        name
    }
  }
`;

export const QUERY_All_USER = gql`
 query AllUsers($search: String, $cursor: Cursor) {
     allUsers(search: $search pagination: { first: 7, after: $cursor }){
        pageInfo{
            endCursor
            hasNextPage
        }
        edges{
          node{
            id
            firstname
            lastname
            profile_type        
            birthdate
            profile_image
          }
        }   
    }       
  }
`;

export const QUERY_All_USER_FOLLOWING = gql`
 query AllUsersFollowing($search: String, $cursor: Cursor) {
     allUsersFollowing(search: $search pagination: { first: 7, after: $cursor }){
        pageInfo{
            endCursor
            hasNextPage
        }
        edges{
          node{
            id
            firstname
            lastname
            profile_type
            birthdate
            profile_image
          }
        }
    }
  }
`;
