import React from 'react';
import './style.css';

const LoadingScreen: React.FC = () => {
  return(
    <div className='loading-screen-container'>
      <div className="snippet" data-title=".dot-flashing">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
