/**
 * App Colors:
 * This contains all the color config for the application
 */

type ThemeStaticType = {
  color: string;
  gray: string;
  mediumGray: string;
  lightGray: string;
  accent: string,
  white: string,
  black: string,
};


export const ThemeStatic: ThemeStaticType = {
  accent: '#ef4232',
  color: '#00ABD5',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#4D4C4C',
  mediumGray: '#CDCDCD',
  lightGray: '#e7e6e6'
};
