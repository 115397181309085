import React from 'react';
import { DateFormatter } from 'ui/utils/DateFormatter';
import { Link } from 'react-router-dom';
import './style.css';

interface ProfileItemProps {
  user: Record<string, any>;
}

const SearchItem: React.FC<ProfileItemProps> = ({ user }) => {
  return(
    <div id="search-item" className="d-flex flex-row align-items-center">
      <Link className="search-item-link" to={`/profile-user/${user.id}`}>
        <div className="flex-grow-0">
          <div className="search-image" style={{ backgroundImage: 'url('+user.profile_image+')' }} />
        </div>
        <div className="d-flex flex-column mx-2 flex-grow-1 search-item-data-content">
          <div className="name">{user.firstname} {user.lastname}</div>
          <div className="type">{user.profile_type}, {DateFormatter.personAge(user.birthdate)}</div>
        </div>
      </Link>
    </div>
  );
};
export default SearchItem;
