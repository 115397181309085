import React, { useEffect, useState } from 'react';
import ResetPasswordUI from './ui';
import { Validator } from 'ui/utils/Validator';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_USER } from 'infrastructure/graphql/query';
import { MUTATION_RESET_PASSWORD } from 'infrastructure/graphql/mutation';


const ResetPassword: React.FC = () => {
  const history = useHistory();
  const { userId, token } = useParams<Record<string, any>>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);

  const [resetPassword, { loading }] = useMutation(MUTATION_RESET_PASSWORD);

  const { data } = useQuery(QUERY_USER, { variables: { id: userId }, fetchPolicy: 'network-only' });

  useEffect(() => { setEmail(data?.user.email); }, [data]);

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => { setPassword(event.target.value); };

  const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => { setConfirmPassword(event.target.value); };

  const validateEmptyPassword = () => {
    if (Validator.emptyField(password)) { setPasswordError('You must enter your password'); throw Error; } else { setPasswordError(''); }
  };

  const validateConfirmPassword = () => {
    if (password != confirmPassword) { setConfirmPasswordError('Password and confirm password does not match'); throw Error; } else { setConfirmPasswordError(''); }
  };

  const tryReset = async () => {
    try {
      validateEmptyPassword();
      validateConfirmPassword();
      const variables = { email: email, password: password, token: token };
      await resetPassword({ variables });
      setResetSuccess(true);
      redirectToLoginInFewSeconds();
    } catch (e) {
      setServerError(e.message);
    }
  };

  const redirectToLoginInFewSeconds = () => {
    setTimeout(() => {history.push('/');}, 3000);
  };

  return(
    <ResetPasswordUI
      email={email}
      password={password}
      passwordError={passwordError}
      confirmPassword={confirmPassword}
      confirmPasswordError={confirmPasswordError}
      handlePassword={handlePassword}
      handleConfirmPassword={handleConfirmPassword}
      onClickReset={tryReset}
      isLoading={loading}
      serverError={serverError}
      resetSuccess={resetSuccess}
    />
  );
};

export default ResetPassword;
