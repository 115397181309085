export const CountryItems = [
  { label: 'Andorra', value: '1' },
  { label: 'Angola', value: '2' },
  { label: 'Argentina', value: '3' },
  { label: 'Armenia', value: '4' },
  { label: 'Australia', value: '5' },
  { label: 'Austria', value: '6' },
  { label: 'Belgium', value: '7' },
  { label: 'Bolivia', value: '8' },
  { label: 'Bosnia and Herzegovina', value: '9' },
  { label: 'Brazil', value: '10' },
  { label: 'Bulgaria', value: '11' },
  { label: 'Cameroon', value: '12' },
  { label: 'Canada', value: '13' },
  { label: 'Chile', value: '14' },
  { label: 'China', value: '15' },
  { label: 'Colombia', value: '16' },
  { label: 'Costa Rica', value: '17' },
  { label: 'Croatia', value: '18' },
  { label: 'Cuba', value: '19' },
  { label: 'Denmark', value: '20' },
  { label: 'Dominican Republic (the)', value: '21' },
  { label: 'Ecuador', value: '22' },
  { label: 'Egypt', value: '23' },
  { label: 'El Salvador', value: '24' },
  { label: 'Ethiopia', value: '25' },
  { label: 'Fiji', value: '26' },
  { label: 'Finland', value: '27' },
  { label: 'France', value: '28' },
  { label: 'Georgia', value: '29' },
  { label: 'Germany', value: '30' },
  { label: 'Ghana', value: '31' },
  { label: 'Gibraltar', value: '32' },
  { label: 'Greece', value: '33' },
  { label: 'Guatemala', value: '34' },
  { label: 'Guernsey', value: '35' },
  { label: 'Honduras', value: '36' },
  { label: 'Hong Kong', value: '37' },
  { label: 'Hungary', value: '38' },
  { label: 'Iceland', value: '39' },
  { label: 'India', value: '40' },
  { label: 'Indonesia', value: '41' },
  { label: 'Iran (Islamic Republic of)', value: '42' },
  { label: 'Iraq', value: '43' },
  { label: 'Ireland', value: '44' },
  { label: 'Israel', value: '45' },
  { label: 'Italy', value: '46' },
  { label: 'Jamaica', value: '47' },
  { label: 'Japan', value: '48' },
  { label: 'Jordan', value: '49' },
  { label: 'Kazakhstan', value: '50' },
  { label: 'Kenya', value: '51' },
  { label: "Korea (the Democratic People's Republic of)", value: '52' },
  { label: 'Korea (the Republic of)', value: '53' },
  { label: 'Kuwait', value: '54' },
  { label: 'Lebanon', value: '55' },
  { label: 'Liberia', value: '56' },
  { label: 'Libya', value: '57' },
  { label: 'Lithuania', value: '58' },
  { label: 'Luxembourg', value: '59' },
  { label: 'Malaysia', value: '60' },
  { label: 'Mexico', value: '61' },
  { label: 'Monaco', value: '62' },
  { label: 'Montenegro', value: '63' },
  { label: 'Morocco', value: '64' },
  { label: 'Mozambique', value: '65' },
  { label: 'Namibia', value: '66' },
  { label: 'Nauru', value: '67' },
  { label: 'New Zealand', value: '68' },
  { label: 'Nicaragua', value: '69' },
  { label: 'Nigeria', value: '70' },
  { label: 'Norway', value: '71' },
  { label: 'Oman', value: '72' },
  { label: 'Pakistan', value: '73' },
  { label: 'Panama', value: '74' },
  { label: 'Paraguay', value: '75' },
  { label: 'Peru', value: '76' },
  { label: 'Philippines (the)', value: '77' },
  { label: 'Poland', value: '78' },
  { label: 'Portugal', value: '79' },
  { label: 'Puerto Rico', value: '80' },
  { label: 'Qatar', value: '81' },
  { label: 'Romania', value: '82' },
  { label: 'Russian Federation (the)', value: '83' },
  { label: 'Samoa', value: '84' },
  { label: 'San Marino', value: '85' },
  { label: 'Saudi Arabia', value: '86' },
  { label: 'Senegal', value: '87' },
  { label: 'Serbia', value: '88' },
  { label: 'Singapore', value: '89' },
  { label: 'South Africa', value: '90' },
  { label: 'Spain', value: '91' },
  { label: 'Sweden', value: '92' },
  { label: 'Switzerland', value: '93' },
  { label: 'Taiwan (Province of China)', value: '94' },
  { label: 'Thailand', value: '95' },
  { label: 'Togo', value: '96' },
  { label: 'Tonga', value: '97' },
  { label: 'Trinidad and Tobago', value: '98' },
  { label: 'Tunisia', value: '99' },
  { label: 'Turkey', value: '100' },
  { label: 'Ukraine', value: '101' },
  { label: 'United Arab Emirates (the)', value: '102' },
  { label: 'United Kingdom of Great Britain and Northern Ireland (the)', value: '103' },
  { label: 'United States Minor Outlying Islands (the)', value: '104' },
  { label: 'United States of America (the)', value: '105' },
  { label: 'Uruguay', value: '106' },
  { label: 'Venezuela', value: '107' },
  { label: 'Yemen', value: '108' },
  { label: 'Zambia', value: '109' },
  { label: 'Zimbabwe', value: '110' }
];
