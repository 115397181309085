import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { QUERY_All_USER_FOLLOWING } from 'infrastructure/graphql/query';
import { useQuery } from '@apollo/client';
import ProfileItem from './components/ProfileItem';
import LoadingScreen from 'ui/components/LoadingScreen';
import './style.css';

interface ProfileModalProps {
  visibleProfilesModal: boolean;
  hideProfileModal: () => void;
  me?: Record<string, any>;
}

const ProfilesModal: React.FC<ProfileModalProps> = ({ visibleProfilesModal, hideProfileModal }) => {
  const { data, loading, refetch } = useQuery(QUERY_All_USER_FOLLOWING, { fetchPolicy: 'network-only' });
  const refetchUsers = () => {
    refetch();
  };
  useEffect(()=>{

  },[data, refetch]);
  return(
    <Modal id="profiles-modal" show={visibleProfilesModal} onHide={hideProfileModal}>
      <Modal.Header closeButton>
        <Modal.Title>Following</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? <LoadingScreen/> :
          data.allUsersFollowing.edges.map((user: Record<string, any>, index: number) => <ProfileItem onClick={refetchUsers} key={index} user={user.node} />)
        }
      </Modal.Body>
    </Modal>
  );
};
export default ProfilesModal;
