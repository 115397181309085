import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import LineIcon from 'ui/theme/icons/LineIcon';
import PrimaryButton from 'ui/components/form/PrimaryButton';
import ContactModal from './components/ContactModal';
import { DateFormatter } from 'ui/utils/DateFormatter';
import { DataFormatter } from 'ui/utils/DataFormatter';
import FollowingButton from 'ui/utils/FollowingButton';
import './style.css';

interface ProfileHeaderProps {
  data: Record<string, any>;
  guest?: boolean;
  screen: 'me' | 'user';
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ data, guest, screen }) => {
  const [visibleContactModal, setVisibleContactModal] = useState<boolean>(false);

  const hideContactModal = () => { setVisibleContactModal(false); };
  const showContactModal = () => { setVisibleContactModal(true); };

  const getCountry = () => {
    return( screen === 'user' && data.profile_type !== 'Artists') ? data.country : DataFormatter.country(data.country);
  };

  const getThumbnailSize = (image: string) => {
    const imageT = image.replace('.jpeg','_350x350.jpeg').replace('.png','_350x350.png').replace('.jpg','_350x350.jpg').replace('.svg','_350x350.svg');
    return imageT;
  };

  return(
    <Row className="justify-content-md-center profile-header-container">
      <ContactModal visibleContactModal={visibleContactModal} hideContactModal={hideContactModal} name={data.firstname} />
      <Col className="d-flex flex-row profile-header-content">
        <Col xs={{ span: 6, offset: 0 }}  md={{ span: 6, offset: 2 }} lg={{ span: 4, offset: 3 }} className="d-flex flex-column justify-content-center profile-data">
          <div className="name">{data.firstname} {data.lastname}</div>
          <div className="type">
            {data.profile_type ? data.profile_type : data.role}
            {data.profile_type !== 'Companies/Providers' && <>, {DateFormatter.personAge(data.birthdate)} years </>}
          </div>
          <div className="location d-flex flex-row"><LineIcon name="map-marker" size={15} /><span className="location-text">{getCountry()}</span></div>
          <div className="location d-flex flex-row">
            {guest && <PrimaryButton label="Contact" large={'small'} onClick={showContactModal} style="mt-2" />}
            {!guest && <FollowingButton onClick={()=> {}} userId={data.id} following={false} />}
          </div>
        </Col>
        <Col className="profile-img-container" >
          <div className="profile-img" style={{ backgroundImage: 'url(' + getThumbnailSize(data.profile_image) + ')' }}  />
        </Col>
      </Col>
    </Row>
  );
};
export default ProfileHeader;
