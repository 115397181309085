import React from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'ui/MyProfile/Login/images/logo-white.svg';
import PrimaryButton from '../../components/form/PrimaryButton';
import FormInput from '../../components/form/FormInput';
import './style.css';

interface ForgotPasswordUIProps {
  email: string,
  emailError: string;
  handleEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tryReset: any;
  isLoading: boolean;
  serverError: string;
  emailSended: boolean;
}

const ForgotPasswordUI: React.FC<ForgotPasswordUIProps> = ({ email, emailError, tryReset, isLoading, handleEmail, serverError, emailSended }) => {
  return(
    <div className="container-login">
      <Row>
        <Col className="text-center mt-md-5">
          <img alt="logo" src={logo} className="logo" />
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-md-3 mb-mb-5 login-subtitle">
          Enter here your password.<br/> You will receive an email with reset instructions.
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login">
            <FormInput type="email" value={email} onChange={handleEmail} placeholder="Enter email" error={emailError}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login mt-2 text-center">
            <PrimaryButton label="Send email verification" loading={isLoading} onClick={tryReset} style="mt-5" loadingMessage="Sending data..." />
            {serverError && <div className="login-server-error">{serverError}</div>}
            {emailSended && <div className="email-sended-message">The email has been sent successfully. Check your email account to follow the next steps.</div>}
          </div>
        </Col>
      </Row>
    </div>
  );
};


export default ForgotPasswordUI;
