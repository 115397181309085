import React from 'react';
import { Button } from 'react-bootstrap';
import './style.css';

interface PrimaryButtonProps {
  label: string
  loading?: boolean;
  onClick: () => void;
  large?: 'medium' | 'small' | 'large';
  size?: 'sm' | 'lg',
  style?: string;
  alternativeButton?: boolean;
  loadingMessage?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ label, loading, onClick, large, size, style, alternativeButton, loadingMessage }) => {

  const getButtonClassname = () => {
    let classname = large;
    classname += alternativeButton ? ' alternative' : '';
    return classname;
  };

  return(
    <div className={style}>
      <Button variant="primary btn-sm" size={size} disabled={loading} onClick={onClick} className={getButtonClassname()}>{loading ? loadingMessage || 'Loading...' : label}</Button>
    </div>
  );
};


export default PrimaryButton;
