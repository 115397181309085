import React from 'react';
import 'ui/theme/fonts/avenir/style.css';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
  from
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { SessionStorage } from './ui/utils/SessionStorage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Profile from './ui/MyProfile';
import UserProfile from './ui/UserProfile';
import Activation from './ui/Activation';
import Logout from './ui/MyProfile/Logout';
import ForgotPassword from './ui/MyProfile/ForgotPassword';
import ResetPassword from './ui/MyProfile/ResetPassword';
import CookiePolicy from 'ui/components/screen/CookiePolicy';
import PrivacyPolicy from 'ui/components/screen/PrivacyPolicy';
import TermsAndConditions from 'ui/components/screen/TermsAndConditions';
import 'style.css';
import { UserProvider } from './ui/UserProvider';

const App: React.FC = () => {

  const httpLink = new HttpLink({ uri: 'https://s1.showunited.com/graphql/' });

  const authMiddleware = setContext(operation => {
    return {
      headers: {
        'x-token': !SessionStorage.get() ? '' : SessionStorage.get()
      }
    };
  });

  const client = new ApolloClient({
    link: from([authMiddleware, httpLink]),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <Router basename='app'>
          <Switch>
            <Route exact path='/' component={Profile} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <Route path='/reset-password/:userId/:token' component={ResetPassword} />
            <Route path='/profile' component={Profile} />
            <Route path='/activation' component={Activation} />
            <Route path='/terms-and-conditions' component={TermsAndConditions} />
            <Route path='/privacy-policy' component={PrivacyPolicy} />
            <Route path='/cookie-policy' component={CookiePolicy} />
            <Route path='/profile-user/:profileId' component={UserProfile} />
            <Route path='/logout' component={Logout} />
          </Switch>
        </Router>
      </UserProvider>
    </ApolloProvider>
  );
};

export default App;
