import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import logo from 'ui/theme/images/logo-black.svg';
import { Link } from 'react-router-dom';
import SearchBar from './components/SearchBar';
import Menu from './components/Menu';
import LineIcon from 'ui/theme/icons/LineIcon';
import ShareModal from './components/ShareModal';
import ProfilesModal from './components/ProfilesModal';
import { SessionStorage } from 'ui/utils/SessionStorage';
import './style.css';

interface HeaderProps {
  data: Record<string, any>;
  me?: Record<string, any>;
  guest?: boolean;
}

const Header: React.FC<HeaderProps> = ({ data, me, guest }) => {
  const [visibleShareModal, setVisibleShareModal] = useState<boolean>(false);
  const [visibleProfilesModal, setVisibleProfilesModal] = useState<boolean>(false);
  const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
  const isMobile = window.innerWidth < 425;


  const showShareModal = () => { setVisibleShareModal(true); };

  const hideShareModal = () => { setVisibleShareModal(false); };

  const showProfilesModal = () => { setVisibleProfilesModal(true);};

  const hideProfilesModal = () => { setVisibleProfilesModal(false); };

  const loggedIn = () => SessionStorage.get();

  const getThumbnailSize = (image: string) => {
    return image.replace('.jpeg', '_350x350.jpeg').replace('.png', '_350x350.png').replace('.jpg', '_350x350.jpg').replace('.svg', '_350x350.svg');
  };

  return(
    <div className="header fixed-top">
      <Row className="container-web">
        <Col className="logo-container">
          <Link to="/"><img alt="logo" src={logo} width={100} height={100} /></Link>
        </Col>

        <Button className="icon-container show-xs" onClick={() => {setShowMobileSearch(!showMobileSearch);}}><LineIcon name={ showMobileSearch ? 'close' : 'search' } size={isMobile ? 23 : 28} /></Button>
        {showMobileSearch && <Col className={'d-flex flex-row justify-content-center align-items-center'}>
          <SearchBar />
        </Col>}
        <Col className={'d-flex flex-row justify-content-center align-items-center hide-xs'}>
          <SearchBar />
        </Col>
        {!showMobileSearch &&
          <Col className={'d-flex flex-row justify-content-end align-items-center'}>
            { loggedIn() && <>
              <ProfilesModal visibleProfilesModal={visibleProfilesModal} hideProfileModal={hideProfilesModal} me={me}  />
              <Button className="icon-container" onClick={showProfilesModal}><LineIcon name="user-search" size={isMobile ? 23 : 28} /></Button>
              <div className="profile-avatar-container">
                <Link to="/">
                  <div className="profile-avatar-content">
                    <div className="profile-avatar" style={{ backgroundImage: `url(${getThumbnailSize(me!.profile_image)})` }}></div>
                  </div>
                </Link>
              </div>
            </>}
            <ShareModal visibleShareModal={visibleShareModal} hideShareModal={hideShareModal} data={data} />
            <Button className="icon-container" onClick={showShareModal}><LineIcon name="share" size={isMobile ? 22 : 27} /></Button>
            { loggedIn() && <Menu /> }
          </Col>}
      </Row>
    </div>
  );
};


export default Header;
