import React from 'react';
import ManAnkleGirth from './images/man-ankle-girth.png';
import ManAnkleToHip from './images/man-ankle-to-hip.png';
import ManAnkleToKnee from './images/man-ankle-to-knee.png';
import ManFullBust from './images/man-full-bust.png';
import ManFullHip from './images/man-full-hip.png';
import ManHighBust from './images/man-high-bust.png';
import ManInseam from './images/man-inseam.png';
import ManKneeGirth from './images/man-knee-girth.png';
import ManNeck from './images/man-neck.png';
import ManShoe from './images/man-shoe.png';
import ManSleeve from './images/man-sleeve.png';
import ManWaist from './images/man-waist.png';
import ManHighHip from './images/man-high-hip.png';
import ManHeight from './images/man-height.png';
import WomanAnkleGirth from './images/woman-ankle-girth.png';
import WomanAnkleToHip from './images/woman-ankle-to-hip.png';
import WomanAnkleToKnee from './images/woman-ankle-to-knee.png';
import WomanFullBust from './images/woman-full-bust.png';
import WomanFullHip from './images/woman-full-hip.png';
import WomanHighBust from './images/woman-high-bust.png';
import WomanInseam from './images/woman-inseam.png';
import WomanKneeGirth from './images/woman-knee-girth.png';
import WomanNeck from './images/woman-neck.png';
import WomanShoe from './images/woman-shoe.png';
import WomanSleeve from './images/woman-sleeve.png';
import WomanWaist from './images/woman-waist.png';
import WomanHighHip from './images/woman-high-hip.png';
import WomanHeight from './images/woman-height.png';

import './style.css';

const images: Record<string, any> = {
  'man-height': ManHeight,
  'man-weight': ManHeight,
  'man-hair-color': ManHeight,
  'man-ankle-girth' : ManAnkleGirth,
  'man-ankle-to-hip': ManAnkleToHip,
  'man-ankle-to-knee': ManAnkleToKnee,
  'man-full-bust': ManFullBust,
  'man-full-hip': ManFullHip,
  'man-high-hip': ManHighHip,
  'man-high-bust': ManHighBust,
  'man-inseam': ManInseam,
  'man-knee-girth': ManKneeGirth,
  'man-neck': ManNeck,
  'man-shoe': ManShoe,
  'man-sleeve': ManSleeve,
  'man-waist': ManWaist,
  'man-show-size': ManHeight,
  'woman-height': WomanHeight,
  'woman-weight': WomanHeight,
  'woman-hair-color': WomanHeight,
  'woman-ankle-girth' : WomanAnkleGirth,
  'woman-ankle-to-hip': WomanAnkleToHip,
  'woman-ankle-to-knee': WomanAnkleToKnee,
  'woman-full-bust': WomanFullBust,
  'woman-full-hip': WomanFullHip,
  'woman-high-hip': WomanHighHip,
  'woman-high-bust': WomanHighBust,
  'woman-inseam': WomanInseam,
  'woman-knee-girth': WomanKneeGirth,
  'woman-neck': WomanNeck,
  'woman-shoe': WomanShoe,
  'woman-sleeve': WomanSleeve,
  'woman-waist': WomanWaist,
  'woman-show-size': WomanHeight,
};

interface BodyImagesProps {
  type: 'man' | 'woman',
  positionBody: string;
}

const BodyImages: React.FC<BodyImagesProps> = ({ type, positionBody }) => {

  return (
    <img src={images[type + '-' + positionBody]} className="img-fluid body-image"/>
  );
};

export default BodyImages;
