import gql from 'graphql-tag';

export const MUTATION_SIGNIN = gql`
  mutation SignIn(
    $email: String!
    $password: String!
  ) {
    signIn(email: $email, password: $password) {
      token
    }
  }
`;

export const MUTATION_SEND_CONTACT_EMAIL = gql`
    mutation SendContactEmail(
        $id: ID!
        $name: String
        $phone: String
        $email: String
        $message: String
  ) {
    sendContactEmail(
        id: $id,
        name: $name,
        phone: $phone,
        email: $email,
        message: $message
    ) {
        success
        msg
    }
  }
`;

export const MUTATION_UPDATE_FOLLOW_DATA_USER = gql`
    mutation UpdateUser(
        $following: [String]
  ) {
    updateUser(
        following: $following,
    ) {
        firstname
        lastname
        nickname
        email
        phone
        role
        gender
        birthdate
        country
        profile_type
        categories
        sub_categories
        about_yourself
        profile_image
        company_name
        city_address
        supported_brands
        company_project
        company_contact
        company_description
        gallery_images
        gallery_videos {
          video_id
          title
          hsl_url
          mp4_url
          status
          image {
            sm
            xs
          }
        }
        following
        measurement {
          id
          height
          weight
          hair_color
          high_bust
          full_bust
          waist
          high_hip
          full_hip
          ankle_to_hip
          ankle_to_knee
          ankle_girth
          knee_girth
          inseam
          shoe_size
        }
    }
  }
`;

export const MUTATION_SEND_EMAIL_RESET_PASSWORD = gql`
  mutation sendResetPassword($email: String!) { 
    sendResetPassword(email: $email) {
      success
      msg
    }
  }
`;


export const MUTATION_RESET_PASSWORD = gql`
  mutation ResetPassword(
        $email: String!
        $password: String!
        $token: String!
  ) {
    resetPassword(
        email: $email,
        password: $password,
        token: $token
    ) {
        success
        msg
    }
  }
`;



