import React, { useEffect, useState } from 'react';
import MeasureItem from './components/MeasureItem';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { QUERY_USER_MEASURES } from 'infrastructure/graphql/query';
import BodyImages from './components/BodyImages';
import { useParams } from 'react-router';
import LoadingScreen from 'ui/components/LoadingScreen';

const type = 'man';

const Measures: React.FC = () => {
  const params = useParams<Record<string, any>>();
  const [position, setPosition] = useState<string>('height');
  const { loading, data } = useQuery(QUERY_USER_MEASURES, { variables: { id: params.profileId } });
  useEffect(() => console.log('DATA', params.profileId), [data]);
  if (loading) return <LoadingScreen />;
  return(
    <div className="mt-2 mx-md-6">
      <Row>
        <Col xs={6} className="d-flex justify-content-end">
          <BodyImages type={type} positionBody={position} />
        </Col>
        {data.user.measurement && <Col xs={6}>
          <MeasureItem selected={position === 'height'} label={'Height'} value={data.user.measurement.height} suffix={'cm'} onClick={() => setPosition('height')} />
          <MeasureItem selected={position === 'weight'} label={'Weight'} value={data.user.measurement.weight} suffix={'kg'} onClick={() => setPosition('weight')} />
          <MeasureItem selected={position === 'hair-color'} label={'Hair color'} value={data.user.measurement.hair_color} onClick={() => setPosition('hair-color')} />
          <MeasureItem selected={position === 'high-bust'} label={'High bust'} value={data.user.measurement.high_bust} suffix={'cm'} onClick={() => setPosition('high-bust')} />
          <MeasureItem selected={position === 'full-bust'} label={'Full bust'} value={data.user.measurement.full_bust} suffix={'cm'} onClick={() => setPosition('full-bust')} />
          <MeasureItem selected={position === 'waist'} label={'Waist'} value={data.user.measurement.waist} suffix={'cm'} onClick={() => setPosition('waist')} />
          <MeasureItem selected={position === 'high-hip'} label={'High hip'} value={data.user.measurement.high_hip} suffix={'cm'} onClick={() => setPosition('high-hip')} />
          <MeasureItem selected={position === 'full-hip'} label={'Full hip'} value={data.user.measurement.full_hip} suffix={'cm'} onClick={() => setPosition('full-hip')} />
          <MeasureItem selected={position === 'ankle-to-hip'} label={'Ankle to hip'} value={data.user.measurement.ankle_to_hip} suffix={'cm'} onClick={() => setPosition('ankle-to-hip')} />
          <MeasureItem selected={position === 'ankle-to-knee'} label={'Ankle to knee'} value={data.user.measurement.ankle_to_knee} suffix={'cm'} onClick={() => setPosition('ankle-to-knee')} />
          <MeasureItem selected={position === 'ankle-girth'} label={'Ankle girth'} value={data.user.measurement.ankle_girth} suffix={'cm'} onClick={() => setPosition('ankle-girth')} />
          <MeasureItem selected={position === 'knee-girth'} label={'Knee girth'} value={data.user.measurement.knee_girth} suffix={'cm'} onClick={() => setPosition('knee-girth')} />
          <MeasureItem selected={position === 'inseam'} label={'Inseam'} value={data.user.measurement.inseam} suffix={'cm'} onClick={() => setPosition('inseam')} />
          <MeasureItem selected={position === 'show-size'} label={'Shoe size'} value={data.user.measurement.shoe_size} suffix={'cm'} onClick={() => setPosition('show-size')} />
        </Col> }
      </Row>
    </div>
  );
};
export default Measures;
