import React from 'react';
import Video from './components/Video';

interface VideosProps {
  videos: Record<string, any>[];
}

const Videos: React.FC<VideosProps> = ({ videos }) => {
  return (videos) ? (
    <div className="d-flex flex-row align-items-center justify-content-center">
      {videos.map((video: Record<string, any>) => <Video key={video.video_id} src={video.mp4_url} image={video.image} />)}
    </div>
  ): (<></>);
};
export default Videos;
