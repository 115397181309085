import moment from 'moment';

export class DateFormatter {

  static birthDateFromTimestamp(birthdateTimestamp: string) {
    return moment(parseInt(birthdateTimestamp)).format('YYYY-MM-DD');
  }

  static personAge(birthdateTimestamp: string) {
    return moment().diff(this.birthDateFromTimestamp(birthdateTimestamp), 'years', false);
  }
}
