import React from 'react';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from 'infrastructure/graphql/query';
import Header from 'ui/components/screen/Header';
import Footer from 'ui/components/screen/Footer';
import LoadingScreen from 'ui/components/LoadingScreen';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const CookiePolicy: React.FC = () => {
  const { loading, data, error } = useQuery(QUERY_ME, {
    fetchPolicy: 'network-only',
  });
  if (loading) return <LoadingScreen />;
  if (error) return <span>ERROR OCURRED</span>;
  return (
    <div className='container-app'>
      <div className='container-fluid content'>
        <Header data={data.me} me={data.me} />
        <Row className='container-dashboard container-web'>
          <Col>
            <div>
              <h1>
                <span>Show United Terms and Conditions of Use</span>
              </h1>
              <h3>
                <span>
                  If you live in the United States, these Terms and Conditions
                  apply to you.
                </span>
              </h3>
              <p>
                <span>You should read these terms and conditions in full.</span>
              </p>
              <ol>
                <li>
                  <span>
                    If you are under the age of 18 then unfortunately you can’t
                    use showunited yet because it’s a meeting place for adults
                    only.
                  </span>
                </li>
                <li>
                  <span>
                    We’re not responsible for anything that you post or say
                    while you are on showunited but if we do see or someone
                    alerts us that you have posted something that we think is
                    inappropriate then we are allowed at our discretion to
                    remove it.
                  </span>
                </li>
                <li>
                  <span>
                    If you post any content that actually belongs to someone
                    else and they get annoyed (or even call in their lawyers),
                    we are not responsible or liable. You have to take
                    responsibility for what you post.
                  </span>
                </li>
                <li>
                  <span>
                    Please refer to our Community Guidelines and Safety Tips for
                    additional Dos and Don’ts, and information on staying safe.
                  </span>
                </li>
                <li>
                  <span>
                    THESE TERMS AND CONDITIONS REQUIRE THE USE OF BINDING
                    ARBITRATION TO SETTLE ANY DISPUTES BETWEEN YOU AND
                    showunited GROUP, WHICH INCLUDES A WAIVER OF JURY TRIALS AND
                    A CLASS ACTION WAIVER.
                  </span>
                </li>
                <li>
                  <span>
                    If you are concerned about how your data is being used by
                    showunited then please refer to our Privacy Policy, which
                    explains how we treat your personal data and protect your
                    privacy when you use showunited. By using showunited, you
                    agree that we can use such data in accordance with our
                    Privacy Policy. Our website and apps also use cookies or
                    similar technologies which we tell you more about
                  </span>
                  <a href='https://showunited.com'>
                    <span>
                      <strong>here.</strong>
                    </span>
                  </a>
                </li>
              </ol>
              <p>
                <span>
                  showunited is a social media website and app designed as a
                  forum for meeting new people, a place to have discussions and
                  to share photos, news and information. It is intended to be a
                  fun place to visit and it is important to us (and for you)
                  that it remains a safe and friendly environment so you agree
                  that you will only use showunited in a manner consistent with
                  its purpose and which is in accordance with these terms and
                  conditions, as well as the showunited Community Guidelines and
                  Safety Tips (collectively, the “Terms”). When we mention
                  “showunited”, we mean www.showunited.com, together with
                  affiliated websites and applications.
                </span>
              </p>
              <p>
                <span>
                  The Terms constitute a binding legal agreement between you as
                  user (“you”) and the showunited SL (“we” or “us”). The
                  showunited Group includes, but is not limited to, showunited
                  SL (an Spanish company whose registered office is at Innovem,
                  Carrer Punta Nati 8, Office 1 &#8211; 07714 Mahon &#8211;
                  SPAIN).
                </span>
                <span>
                  <br />
                </span>
                <span>
                  <br />
                </span>
                <span>
                  The Terms apply whenever you visit showunited, whether or not
                  you have chosen to register with us, so please read them
                  carefully. BY DOWNLOADING showunited, CLICKING ON THE “I
                  ACCEPT” (OR SIMILAR) BUTTON, OR OTHERWISE ACCESSING, USING,
                  REGISTERING FOR OR RECEIVING SERVICES OFFERED ON showunited
                  YOU ARE ACCEPTING AND AGREEING TO BE BOUND BY THE TERMS.
                </span>
              </p>
              <p>
                <span>
                  IF YOU DO NOT ACCEPT AND AGREE TO THE TERMS THEN YOU MUST NOT
                  ACCESS OR USE THE APP OR SITE.
                </span>
              </p>
              <p>
                <span>
                  IF YOU SUBSCRIBE TO ANY PREMIUM SERVICES FOR A TERM (THE
                  “INITIAL TERM”), THEN THE TERMS OF YOUR SUBSCRIPTION WILL BE
                  AUTOMATICALLY RENEWED FOR ADDITIONAL PERIODS OF THE SAME
                  DURATION AS THE INITIAL TERM AT showunited GROUP’S
                  THEN-CURRENT FEE FOR SUCH SERVICES, UNLESS YOU CANCEL YOUR
                  SUBSCRIPTION IN ACCORDANCE WITH SECTION 3 BELOW.
                </span>
              </p>
              <p>
                <span>
                  SECTION 13 OF THESE TERMS INCLUDES A CLASS ACTION WAIVER AND A
                  WAIVER OF JURY TRIALS, AND REQUIRES BINDING ARBITRATION ON AN
                  INDIVIDUAL BASIS TO RESOLVE DISPUTES. IF YOU ARE A USER WHO
                  SIGNED UP FOR showunited BEFORE 27 MAY, 2021, YOU HAVE A RIGHT
                  TO OPT OUT OF THE ARBITRATION AGREEMENT PURSUANT TO SECTION 13
                  BELOW. IF YOU DO NOT OPT OUT OF THE ARBITRATION AGREEMENT IN
                  ACCORDANCE WITH SECTION 13, OR IF YOU SIGN UP FOR showunited
                  ON OR AFTER 27 MAY, 2021, (1) YOU WILL ONLY BE PERMITTED TO
                  PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL
                  BASIS ONLY; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF
                  IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS.
                </span>
              </p>
              <p>
                <span>
                  NOTICE TO CALIFORNIA SUBSCRIBERS: YOU MAY CANCEL YOUR
                  SUBSCRIPTION AND REQUEST A REFUND AT ANY TIME PRIOR TO
                  MIDNIGHT OF THE THIRD BUSINESS DAY FOLLOWING THE DATE YOU
                  SUBSCRIBED. IF YOU SUBSCRIBED USING YOUR APPLE ID, REFUNDS ARE
                  HANDLED BY APPLE, NOT showunited. IF YOU WISH TO REQUEST A
                  REFUND, PLEASE VISIT{' '}
                </span>
                <strong>
                  <a href='https://getsupport.apple.com/'>
                    HTTPS://GETSUPPORT.APPLE.COM
                  </a>
                </strong>
                <span>
                  . IF YOU SUBSCRIBED USING YOUR GOOGLE PLAY STORE ACCOUNT OR
                  THROUGH showunited, YOU MAY UNSUBSCRIBE IN THE APP OR BY
                  CONTACTING CUSTOMER SUPPORT.
                </span>
              </p>
              <h2>
                <span>
                  1. Use of the app and site and rules relating to Content
                </span>
              </h2>
              <h3>
                <span>Who can use showunited?</span>
              </h3>
              <p>
                <span>
                  showunited is a meeting place for adults. You may only use
                  showunited, including any of its features, or become a
                  registered member if you are (i) 18 years old or older and
                  (ii) be legally permitted to use showunited by the laws of
                  your home country.
                </span>
              </p>
              <p>
                <span>
                  You warrant that: (i) you have the right, authority and
                  capacity to enter into and be bound by the Terms, (ii) all
                  registration information you provide is accurate and complete,
                  and (iii) that by using showunited you will not be violating
                  any law or regulation of the country in which you are
                  resident. You are solely responsible for your compliance with
                  all applicable local laws and regulations.
                </span>
              </p>
              <p>
                <span>
                  You further warrant that you have not been convicted of, nor
                  are subject to any court order relating to assault, violence,
                  sexual misconduct or harassment.
                </span>
              </p>
              <h3>
                <span>
                  What kind of content can I post or upload on showunited?
                </span>
              </h3>
              <p>
                <span>
                  You are able to post or upload all kinds of things on
                  showunited, including photographs, emails, text, messages and
                  other content and digital media (“Content”). However, when you
                  are using showunited you may{' '}
                </span>
                <span>not</span>
                <span> post, send or upload any Content which:</span>
              </p>
              <ol>
                <li>
                  <span>
                    contains expletives or language which could be deemed
                    offensive or is likely to harass, upset, embarrass, alarm or
                    annoy any other person;
                  </span>
                </li>
                <li>
                  <span>
                    is obscene, pornographic, illegal or otherwise may offend
                    human dignity;
                  </span>
                </li>
                <li>
                  <span>
                    is abusive, insulting or threatening, or which promotes,
                    depicts or encourages violence, self-harm, suicide, racism,
                    sexism, hatred or bigotry;
                  </span>
                </li>
                <li>
                  <span>
                    encourages any illegal activity including, without
                    limitation, terrorism and inciting racial hatred;
                  </span>
                </li>
                <li>
                  <span>is defamatory;</span>
                </li>
                <li>
                  <span>
                    relates to commercial activities (including, without
                    limitation, sales, competitions and advertising, links to
                    other websites or premium line telephone numbers);
                  </span>
                </li>
                <li>
                  <span>
                    involves the transmission of “junk” mail or “spam”;
                  </span>
                </li>
                <li>
                  <span>
                    impersonates a person, company or brand with the intent to
                    deceive or confuse others;
                  </span>
                </li>
                <li>
                  <span>
                    contains any spyware, adware, viruses, corrupt files, worm
                    programs or other malicious code designed to interrupt,
                    damage or limit the functionality of or disrupt any
                    software, hardware, telecommunications, networks, servers or
                    other equipment, Trojan horse or any other material designed
                    to damage, interfere with, wrongly intercept or expropriate
                    any data or personal data whether from showunited or
                    otherwise;
                  </span>
                </li>
                <li>
                  <span>
                    itself, or the posting of which, violates any law or
                    infringes any third party’s rights (including, without
                    limitation, intellectual property rights, rights of
                    publicity and privacy rights);
                  </span>
                </li>
                <li>
                  <span>
                    includes the image of another person where such Content was
                    created or distributed without that person’s knowledge, and
                    without that person having been afforded an opportunity to
                    refuse such creation or distribution; or
                  </span>
                </li>
                <li>
                  <span>
                    contains images of children, even if you are also in the
                    photo, or endangers or harms minors.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  Please use your common sense when picking the Content that you
                  choose to post on, upload or send via showunited because you
                  are solely responsible for, and bear all liability in relation
                  to, such Content. You agree that you will not develop, support
                  or use software, devices, scripts, robots, other types of
                  mobile code or any other means or processes (including
                  crawlers, browser plugins and add-on or other technology) to
                  scrape or otherwise exfiltrate data or information from
                  showunited or its services, or otherwise copy profiles and
                  other data from the services. If we determine, in our sole
                  discretion, that you infringe our rules or violate the Terms,
                  any showunited user’s rights, or any third party’s rights, or
                  if we are required to do so by applicable law, we may prevent
                  you from using showunited, we may disable your account or
                  block you from further use of showunited without notice and we
                  may remove any such improper, infringing, or otherwise
                  unauthorized Content you submit to showunited.
                </span>
              </p>
              <h3>
                <span>
                  Are there any rules relating to personal data like my email
                  address?
                </span>
              </h3>
              <p>
                <span>
                  You may not display any personal contact or banking
                  information on your individual profile page (“Profile”)
                  whether in relation to you or any other person (for example,
                  names, home addresses or postcodes, telephone numbers, email
                  addresses, URLs, credit/debit card or other banking details,
                  or place of work). If you do choose to reveal any personal
                  data about yourself to other users, whether via email or
                  otherwise, it is at your own risk. We encourage you to use the
                  same caution in disclosing details about yourself to third
                  parties online as you would under any other circumstances.{' '}
                </span>
              </p>
              <h3>
                <span>
                  What about other people’s personal data, can I use it?
                </span>
              </h3>
              <p>
                <span>
                  You may only use other showunited users’ personal data to the
                  extent that your use of it matches showunited’s purpose of
                  allowing people to meet one another. You may not use other
                  users’ information for commercial purposes, to spam, to
                  harass, stalk or to make unlawful threats. showunited reserves
                  the right to terminate your account, or prevent access to its
                  features if you misuse other users’ information.
                </span>
              </p>
              <h3>
                <span>
                  Some of my friends want to see all the people I’m chatting
                  with. Can I tell them my password and let them log on as me?
                </span>
              </h3>
              <p>
                <span>
                  The short answer is no. Only you are authorized to access your
                  account with showunited. You must not share your password with
                  anyone or let anyone else access your account as this
                  jeopardizes the security of all of the Content and personal
                  data that you have submitted to showunited. You are
                  responsible for keeping your password secret and secure.
                  showunited is not liable for any unauthorized access to your
                  account. If you suspect that someone has gained access to your
                  account, you must let us know immediately by visiting our{' '}
                </span>
                <strong>
                  <a href='https://showunited.com'>Website</a>
                </strong>
                <span>
                  {' '}
                  and telling us of your suspicions or concerns. You must also
                  immediately change your password. showunited reserves the
                  right to terminate your account if you violate our rules on
                  keeping your password secure.
                </span>
              </p>
              <h3>
                <span>Who can see the Content that I put on showunited?</span>
              </h3>
              <p>
                <span>
                  When you upload Content to showunited it can be accessed and
                  viewed by the general public. If you do not want such Content
                  to be viewed by others, then you can opt to use the private
                  folders and select the person to whom you wish to give access.
                  If you still do not want such Content to be viewed by others,
                  then you should not upload it to showunited. We reserve the
                  right (without obligation) at our sole discretion and without
                  giving you notice, to remove or edit, limit or block access to
                  any Content that you upload or submit to showunited without
                  any incurring liability to you. We have no obligation to
                  display any Content that you submit to showunited, nor to
                  check the accuracy or truthfulness of any Content submitted to
                  showunited, nor to monitor your use or the use of other users
                  of showunited. Nonetheless, we reserve the right, in our sole
                  discretion, to monitor any Content being uploaded to
                  showunited (including the Content you post), and by entering
                  into these Terms, you acknowledge and agree that we may engage
                  in such monitoring.
                </span>
              </p>
              <h3>
                <span>Anything else I need to know?</span>
              </h3>
              <p>
                <span>
                  When you use showunited you agree to the Terms, and in return
                  we grant you a limited, personal, non-exclusive and
                  non-transferable license to use and to upload Content and to
                  use showunited solely for your personal use. Apart from this
                  license, and the rights you retain in the Content that you
                  submit to showunited, you have no other rights in the use of
                  showunited, or its Content, features, database(s) or software,
                  and you may not modify, edit, copy, reproduce, create
                  derivative works of, reverse engineer, alter, enhance, scrape,
                  disable, decompile, analyze or in any way exploit any of the
                  foregoing in any manner. If you breach any of the Terms, the
                  above license will terminate automatically and you must
                  immediately destroy any downloaded, printed or otherwise
                  obtained Content. Furthermore, with respect to any mobile
                  application downloaded from the Apple App Store, you will only
                  use the application on an Apple-branded product that runs the
                  iOS (Apple’s proprietary operating system), and (b) as
                  permitted by the “Usage Rules” set forth in the Apple App
                  Store Terms of Service.
                </span>
              </p>
              <h2>
                <span>2. Ownership of Content</span>
              </h2>
              <h3>
                <span>
                  Once I have uploaded Content on showunited, do I still own it?
                </span>
              </h3>
              <p>
                <span>
                  Yes (provided you are the rightful owner in the first place —
                  please see our other rules regarding posting Content that
                  doesn’t belong to you).
                </span>
              </p>
              <p>
                <span>
                  Please note though that by posting, uploading or sending
                  Content on showunited you represent and warrant to us that you
                  exclusively own such Content and/or otherwise have the right
                  to grant us the rights to use such Content in accordance with
                  these Terms.
                </span>
              </p>
              <p>
                <span>
                  Please note that by posting, uploading or sending Content on
                  showunited you automatically grant to us a non-exclusive,
                  royalty free, perpetual, worldwide license to use such Content
                  in any way (including, without limitation, editing, copying,
                  modifying, adapting, translating, reformatting, creating
                  derivative works from, incorporating into other works,
                  advertising, distributing and otherwise making available to
                  the general public such Content, whether in whole or in part
                  and in any format or medium currently known or developed in
                  the future).
                </span>
              </p>
              <p>
                <span>
                  We may assign and/or sub-license the above license to our
                  affiliates and successors without any further approval by, or
                  notice to, you.
                </span>
              </p>
              <p>
                <span>
                  We have the right to disclose your identity to any third party
                  who claims that any Content posted or uploaded by you to
                  showunited constitutes a violation of their intellectual
                  property, privacy or other rights.
                </span>
              </p>
              <h3>
                <span>
                  Who does the rest of the Content on showunited belong to then?
                </span>
              </h3>
              <p>
                <span>
                  Us! The rest of the Content, including text, graphics, user
                  interfaces, trademarks, logos, sounds and artwork on
                  showunited, as well as the showunited software and database(s)
                  are owned, controlled or licensed by us and our suppliers and
                  are protected by copyright, trademark, database rights and/or
                  other intellectual property law rights.
                </span>
              </p>
              <h3>
                <span>
                  Can I use any of the Content that doesn’t belong to me?
                </span>
              </h3>
              <p>
                <span>
                  Other than in relation to Content submitted by you to
                  showunited, and apart from the license we grant to you under
                  these Terms, you do not have any rights in relation to the
                  Content on showunited and you agree that you will not use any
                  Content in any manner which may infringe our or any third
                  party’s rights. All other rights that are not expressly
                  granted under these Terms are reserved by showunited and its
                  licensors. This means that you agree that you will not copy,
                  modify, adapt, distribute, publish or sell all or any part of
                  showunited or the Content contained on showunited (other than
                  the Content submitted by you).
                </span>
              </p>
              <h2>
                <span>3. Paid for services</span>
              </h2>
              <h3>
                <span>What can I buy on showunited?</span>
              </h3>
              <p>
                <span>
                  showunited offers you the opportunity to pay for certain
                  services offered on showunited (“Premium Services”).
                  showunited’s Premium Services include various opportunities to
                  find and meet additional people, to customize your profile and
                  to boost your profile so more people find you. All of these
                  Premium Services can be purchased after you register, and you
                  may subscribe to the Premium Services for various lengths of
                  time as described within showunited.
                </span>
              </p>
              <p>
                <span>
                  Paid for services are not available in sanctioned countries.
                </span>
              </p>
              <h3>
                <span>How do payments generally work?</span>
              </h3>
              <p>
                <span>
                  If you order any Premium Services or Credits on showunited,
                  you agree to pay for them in accordance with the billing terms
                  in effect at the time of purchase. You may purchase Premium
                  Services or Credits through the following payment methods
                  (each, a “Premium Payment Method”): (a) making a purchase
                  through a third party platform such as the Apple App Store or
                  Google Play Store (each, a “Third Party Store”), or (b) paying
                  with any credit or debit card that we accept or (c) a PayPal
                  account or (d) any other payment method available to you at
                  your current location from time to time. Once you have
                  requested a Premium Service or Credit, you authorize us to
                  charge your chosen Premium Payment Method. If we do not
                  receive payment from your chosen Premium Payment Method, you
                  agree to promptly pay all amounts due upon demand by us.
                </span>
              </p>
              <h3>
                <span>Can I automatically refill my credits?</span>
              </h3>
              <p>
                <span>
                  Yes. showunited may also offer users the ability to
                  automatically refill their Credits with our Auto Refill
                  feature (“Auto Refill”). By default, our Auto Refill feature
                  is turned on, your Credits will be automatically refilled to
                  an amount equal to the amount you initially paid using such
                  feature and your Premium Payment Method will be automatically
                  charged when your Account has 200 Credits or less. You can
                  change your Auto Refill at any time in your payment settings
                  on your account’s profile page. The pricing may vary due to a
                  number of factors, such as (but not limited to) promotional
                  offers, loyalty bonuses and other discounts.
                </span>
              </p>
              <h3>
                <span>
                  How do I cancel automatic renewal of my Premium Services with
                  showunited?
                </span>
              </h3>
              <p>
                <span>
                  Premium Services may be provided as automatically-renewing
                  subscriptions (e.g., an automatically renewing one-week
                  subscription, one-month subscription, or three-month
                  subscription.). IF YOU PURCHASE AN AUTOMATICALLY RENEWING
                  SUBSCRIPTION, YOUR SUBSCRIPTION WILL RENEW AT THE END OF THE
                  INITIAL SUBSCRIPTION PERIOD AND EACH SUBSEQUENT RENEWAL PERIOD
                  AT showunited THEN CURRENT PRICE FOR SUCH SUBSCRIPTION, UNLESS
                  YOU CANCEL. To avoid charges for a new subscription period,
                  you must cancel at least twenty four (24) hours before the end
                  of the prior subscription period as described below. Deleting
                  your account or deleting the application from your device DOES
                  NOT cancel your subscription. You will be given notice of
                  changes in the pricing of the Premium Services for which you
                  have subscriptions and an opportunity to cancel. If showunited
                  SL changes these prices and you do not cancel your
                  subscription, you agree that you will be charged at showunited
                  Group’s then-current pricing for subscription.
                </span>
              </p>
              <p>
                <span>
                  If you purchased Premium Services or a subscription directly
                  from showunited Group, you may change your Premium Payment
                  Method via the payment settings option under your profile. If
                  you purchased a subscription directly from showunited Group or
                  the Google Play Store and you would like to cancel your
                  subscription, you may do this following the instructions in
                  the App – canceling your Premium Payment Method DOES NOT
                  cancel the subscription. If you purchased a subscription
                  through the Apple App Store, you will need to access your
                  account with Apple and follow instructions to change or cancel
                  your subscription. If you cancel your subscription, you may
                  use your subscription until the end of the period you last
                  paid for, but your subscription will not be renewed when that
                  period expires and you will then no longer be able to use the
                  Premium Services enabled by your subscription.
                </span>
              </p>
              <h3>
                <span>When can I seek a refund?</span>
              </h3>
              <p>
                <span>
                  Generally, all charges for purchases are nonrefundable, and
                  there are no refunds or credits for partially used periods.
                </span>
              </p>
              <p>
                <span>
                  For subscribers residing in Arizona, California, Connecticut,
                  Illinois, Iowa, Minnesota, New York, North Carolina, Ohio,
                  Rhode Island and Wisconsin, the terms below apply:
                </span>
              </p>
              <p>
                <span>
                  You may cancel your subscription, without penalty or
                  obligation, at any time prior to midnight of the third
                  business day following the date you subscribed. In the event
                  that you die before the end of your subscription period, your
                  estate shall be entitled to a refund of that portion of any
                  payment you had made for your subscription which is allocable
                  to the period after your death. In the event that you become
                  disabled (such that you are unable to use the services of
                  showunited) before the end of your subscription period, you
                  shall be entitled to a refund of that portion of any payment
                  you had made for your subscription which is allocable to the
                  period after your disability by providing the company notice
                  in the same manner as you request a refund as described below.
                </span>
              </p>
              <p>
                <span>To request a refund:</span>
              </p>
              <p>
                <span>
                  If you subscribed using your Apple ID, refunds are handled by
                  Apple, not showunited. To request a refund, go to iTunes,
                  click on your Apple ID, select “Purchase history,” find the
                  transaction and hit “Report Problem”. You can also submit a
                  request at{' '}
                </span>
                <strong>
                  <a href='https://getsupport.apple.com/'>
                    https://getsupport.apple.com.
                  </a>
                </strong>
              </p>
              <p>
                <span>
                  If you subscribed using your Google Play Store account or
                  through showunited directly: please contact{' '}
                </span>
                <strong>
                  <a href='https://showunited.com/contact'>customer support</a>
                </strong>
                <span>
                  {' '}
                  with your order number for the Google Play Store (you can find
                  the order number in the order confirmation email or by logging
                  in to Google Wallet). You may also mail or deliver a signed
                  and dated notice which states that you, the buyer, are
                  canceling this agreement, or words of similar effect. Please
                  also include the email address or cell number associated with
                  your account along with your order number. This notice shall
                  be sent to: showunited, Attn.: Cancellations, P.O. Box 300940,
                  Austin, Texas, 78703 (in addition, California and Ohio members
                  may send a facsimile to 512-696-1545).
                </span>
              </p>
              <h3>
                <span>
                  Can I transfer any of my Premium Services or Credits to
                  another showunited user?
                </span>
              </h3>
              <p>
                <span>
                  The Premium Services and Credits are not transferable, whether
                  for free or as a sale or barter transaction, and showunited
                  does not recognize any purported transfers of Premium Services
                  or Credits. Accordingly, you may not trade, sell, gift or
                  attempt to sell any Premium Services or Credits for value of
                  any kind. Any such transfer or attempted transfer is
                  prohibited and void, and will subject your account to
                  termination.
                </span>
              </p>
              <h3>
                <span>Do my Credits last forever?</span>
              </h3>
              <p>
                <span>
                  No. Unused Credits expire 6 months after the date of purchase
                  (unless the credits are purchased through the Apple App
                  Store). If you delete your account or if your account is
                  terminated by us due to breach of these Terms, you will lose
                  any accumulated credits. If you receive free or promotional
                  credits, we may expire them at any time. Credits are not
                  redeemable for any sum of money or monetary value from us
                  unless we agree otherwise in writing.
                </span>
              </p>
              <h3>
                <span>Do sales taxes apply?</span>
              </h3>
              <p>
                <span>
                  The payments required under Section 3 of these Terms do not
                  include any Sales Tax that may be due in connection with the
                  Premium Services provided under these Terms. If showunited
                  Group determines it has a legal obligation to collect a Sales
                  Tax from you, showunited Group shall collect such Sales Tax in
                  addition to the payments required under Section 3 of these
                  Terms. If any Premium Services, or payments for any Premium
                  Services, under these Terms are subject to any Sales Tax in
                  any jurisdiction and you have not remitted the applicable
                  Sales Tax to showunited Group, you will be responsible for the
                  payment of such Sales Tax and any related penalties or
                  interest to the relevant tax authority, and you will indemnify
                  showunited Group for any liability or expense showunited Group
                  may incur in connection with such Sales Taxes. Upon showunited
                  Group’s request, you will provide us with official receipts
                  issued by the appropriate taxing authority, or other such
                  evidence that you have paid all applicable taxes. For purposes
                  of this section, “Sales Tax” shall mean any sales or use tax
                  and any other tax measured by sales proceeds that is the
                  functional equivalent of a sales tax where the applicable
                  taxing jurisdiction does not otherwise impose a sales or use
                  tax.
                </span>
              </p>
              <h3>
                <span>What about withholding taxes?</span>
              </h3>
              <p>
                <span>
                  You agree to make all payments of fees to showunited Group
                  free and clear of, and without reduction for, any withholding
                  taxes. Any such taxes imposed on payments of fees to
                  showunited Group will be your sole responsibility, and you
                  will provide showunited Group with official receipts issued by
                  the appropriate taxing authority, or such other evidence as we
                  may reasonably request, to establish that such taxes have been
                  paid.
                </span>
              </p>
              <h2>
                <span>4. Access to the app and site</span>
              </h2>
              <h3>
                <span>
                  Do you guarantee that showunited will be up and running at all
                  times?
                </span>
              </h3>
              <p>
                <span>
                  Unfortunately we can’t guarantee that because sometimes we
                  have to carry out maintenance to the app or site or they may
                  be affected by a fault or circumstances which are outside our
                  control, so showunited is provided on an “as is” “as
                  available” basis. NO WARRANTY IS GIVEN ABOUT THE QUALITY,
                  ACCURACY, FUNCTIONALITY, AVAILABILITY OR PERFORMANCE OF
                  showunited AND WE RESERVE THE RIGHT TO SUSPEND, WITHDRAW,
                  AMEND, MODIFY OR VARY THE SERVICE PROVIDED ON showunited
                  WITHOUT NOTICE AND WITHOUT INCURRING ANY LIABILITY TO YOU.
                </span>
              </p>
              <h3>
                <span>
                  What about mobile access and communications with showunited
                  Group?
                </span>
              </h3>
              <p>
                <span>
                  You are responsible for making all the necessary arrangements
                  to ensure you can access showunited (including, but not
                  limited to Internet provider and mobile internet provider fees
                  and, any other charges associated with such access). We shall
                  not be held responsible for any reduced functionality you may
                  encounter as result of or in connection with accessing
                  showunited through mobile services or any similar service
                  currently known or developed in the future.
                </span>
              </p>
              <p>
                <span>
                  By entering into these Terms or using showunited, you agree to
                  receive communications from us, including via e-mail, text
                  message, calls, and push notifications. You agree that texts,
                  calls or prerecorded messages may be generated by automatic
                  telephone dialing systems. Communications from us and our
                  affiliated companies may include but are not limited to:
                  operational communications concerning your account or the use
                  of showunited. By accessing showunited or agreeing to receive
                  messages or notifications from showunited through your cell
                  phone and/or any other connected media device, you accept that
                  you may incur charges from your internet or mobile service
                  provider. We shall not under any circumstances be liable for
                  such charges. You may opt-out of receiving certain
                  communications by unsubscribing.
                </span>
              </p>
              <h3>
                <span>
                  I’m not registered and I can’t seem to access some Content or
                  features on the app or site. Why is that?
                </span>
              </h3>
              <p>
                <span>
                  Non-registered users are able to access only that portion of
                  showunited that is publicly available. They will not have a
                  Profile but may have limited ability to upload any Content.
                  The extent of registered users’ access to showunited will
                  depend on certain criteria relating to their Profile being
                  met. We may change or update the criteria from time to time
                  without prior notice and at our discretion.
                </span>
              </p>
              <h2>
                <span>5. Termination of use by you</span>
              </h2>
              <h3>
                <span>
                  What do I do if I want to “unregister” myself from showunited?
                </span>
              </h3>
              <p>
                <span>
                  If you have registered for an account on showunited, you can
                  terminate your account at any time by going to the ‘Settings’
                  control on showunited when you are logged in and choosing the
                  ‘delete profile’ link. We save your profile information in
                  case you later decide to restore your account. Many users
                  deactivate their accounts for temporary reasons and in doing
                  so expect us to maintain their information until they return
                  to showunited. You will therefore be able to restore your
                  account and your profile in its entirety within 28 days of
                  deactivating it. Once your registration is terminated, we have
                  the right to delete any related Content that you have
                  submitted or uploaded on to showunited. For further details,
                  please refer to our Privacy Policy.
                </span>
              </p>
              <h3>
                <span>When might showunited terminate my account?</span>
              </h3>
              <p>
                <span>
                  We reserve the right at our sole discretion to terminate or
                  suspend any Account, or make use of any operational,
                  technological, legal or other means available to enforce the
                  Terms (including without limitation blocking specific IP
                  addresses), at any time without liability and without the need
                  to give you prior notice. Without limiting the foregoing in
                  any way, we expressly reserve the right to terminate or
                  suspend your Account without notice (1) for violating these
                  terms, (2) due to your conduct on the App, or your conduct
                  with other users of the App (including your “offline”
                  conduct), if we, in our sole discretion, determine your
                  conduct was inappropriate or improper, (3) if we or our
                  affiliates, in our or their sole discretion, determine your
                  conduct on other apps operated by our affiliates was
                  inappropriate or improper, or (4) for any reasons whatsoever
                  that we deem, in our sole discretion, justifies termination.
                  If your Account is terminated or suspended, you agree you will
                  not receive a refund for any paid service or features you have
                  already been charged for.
                </span>
              </p>
              <h3>
                <span>What happens once my account has been terminated?</span>
              </h3>
              <p>
                <span>
                  Following termination of these Terms and/or your account, the
                  Content that you have uploaded to showunited, other than your
                  profile (such as comments or email messages), may still appear
                  on showunited following termination of your registration,
                  although showunited may delete such information. In addition,
                  termination of these Terms and/or your account includes
                  removal of access to your account, and all related information
                  and Content associated with or inside your account. We reserve
                  the right to enforce all rights in connection with any Content
                  that remains active or otherwise available on showunited or
                  its services.
                </span>
              </p>
              <p>
                <span>
                  All provisions of these Terms, which by their nature should
                  survive, shall survive termination of these Terms, including,
                  without limitation, ownership provisions, warranty
                  disclaimers, dispute resolution provisions, and limitations of
                  liability.
                </span>
              </p>
              <h2>
                <span>6. Abuse/Complaints</span>
              </h2>
              <h3>
                <span>Someone is not obeying the Terms, who do I tell?</span>
              </h3>
              <p>
                <span>
                  You can report any abuse or complain about Content on
                  showunited by contacting us via our Website, outlining the
                  abuse and or complaint. You can also report a user directly
                  from a profile, by clicking the ‘Report Abuse’ link on their
                  profile, choosing the reason for the complaint and providing
                  us with any additional information you think fit.
                </span>
              </p>
              <p>
                <span>
                  We reserve the right to help attempt to resolve any issue that
                  you may encounter with any other users of our service.
                </span>
              </p>
              <h3>
                <span>Are other users vetted?</span>
              </h3>
              <h3>
                <span>
                  NO. YOU UNDERSTAND THAT IN THE ORDINARY COURSE OF showunited’S
                  OPERATIONS, showunited GROUP DOES NOT CONDUCT CRIMINAL
                  BACKGROUND CHECKS ON ITS USERS OR OTHERWISE REGULARLY INQUIRE
                  INTO THE BACKGROUNDS OF ITS USERS OR ATTEMPT TO VERIFY THE
                  STATEMENTS OF ITS USERS. showunited GROUP MAKES NO
                  REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF ANY USERS
                  OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS.
                  showunited GROUP RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL
                  BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER
                  REGISTER SEARCHES) AT ANY TIME AND TO USE AVAILABLE PUBLIC
                  RECORDS FOR ANY PURPOSE.
                </span>
              </h3>
              <p>
                <span>
                  You agree to, and hereby do, to the maximum extent permissible
                  under applicable law, release showunited Group and its
                  affiliates, and their respective officers, directors,
                  employees, agents, representatives and successors from any
                  claims, demands, losses, damages, rights, and actions of any
                  kind, including personal injuries, death and property damage,
                  that either directly or indirectly arises from your
                  interactions with or conduct of other users of showunited. If
                  you are a California resident, you hereby waive California
                  Civil Code Section 1542, which states, “A general release does
                  not extend to claims that the creditor or releasing party does
                  not know or suspect to exist in his or her favour at the time
                  of executing the release and that, if known by him or her,
                  would have materially affected his or her settlement with the
                  debtor or released party.”
                </span>
              </p>
              <h2>
                <span>7. Privacy Policy</span>
              </h2>
              <h3>
                <span>
                  Do you have guidelines in place about what you can do with my
                  personal data?
                </span>
              </h3>
              <p>
                <span>
                  We process information in accordance with our Privacy Policy
                  which is incorporated into these Terms and Conditions of Use.
                  Please read the terms of our Privacy Policy prior to using
                  showunited.
                </span>
              </p>
              <h2>
                <span>8. Links</span>
              </h2>
              <h3>
                <span>
                  Are the links on the app or site anything to do with
                  showunited?
                </span>
              </h3>
              <p>
                <span>
                  showunited may contain links to other sites, resources and
                  purchase opportunities provided by third parties. These links
                  are provided to you for your information only. If you access
                  these links you may be directed to third party sites. These
                  third party sites will have their own terms of use and privacy
                  policies, which may differ from the Terms. The display of
                  links to third party sites does not constitute an endorsement
                  by us of any of the third party content information, sites, or
                  resources provided.
                </span>
              </p>
              <p>
                <span>
                  Please note that we have no control over the contents of any
                  third party sites or resources, and we accept no
                  responsibility for them, including (but not limited to) the
                  third party site’s compliance with any applicable laws or
                  regulations.
                </span>
              </p>
              <h2>
                <span>9. Disclaimer of warranties</span>
              </h2>
              <h3>
                <span>
                  I forgot the rules about conduct in these Terms and now
                  someone is threatening to sue me. Will your lawyers sort it
                  out?
                </span>
              </h3>
              <p>
                <span>
                  No. If you act in a way that upsets other users then you have
                  to be responsible for the consequences. We expressly disclaim
                  any and all responsibility and liability for your conduct or
                  the conduct of any other user of showunited, and expressly
                  disclaim any liability for Content uploaded by you or by any
                  other user.
                </span>
              </p>
              <h3>
                <span>
                  What about the reliability of showunited and the information
                  contained on it?
                </span>
              </h3>
              <p>
                <span>
                  The information and Content contained on showunited is
                  provided by showunited Group on an “as is”, “as available”
                  basis. showunited DOES NOT GIVE OR MAKE ANY WARRANTY OR
                  REPRESENTATION OF ANY KIND ABOUT showunited OR THE INFORMATION
                  CONTAINED ON showunited, WHETHER EXPRESS OR IMPLIED,
                  INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
                  MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. USE OF
                  showunited AND THE INFORMATION AND CONTENT AVAILABLE ON IT IS
                  AT YOUR SOLE RISK.
                </span>
              </p>
              <p>
                <span>
                  showunited cannot be held responsible for any loss arising
                  from the transmission, use of data, or inaccurate Content
                  posted by users. You are responsible for taking all necessary
                  precautions to ensure that any material you may obtain from
                  showunited is free of viruses or other harmful components. You
                  accept that showunited will not be provided uninterrupted or
                  error free, that defects may not be corrected or that
                  showunited, or the server that makes it available, are free of
                  viruses or bugs, spyware, Trojan horses or any similar
                  malicious software. showunited is not responsible for any
                  damage to your computer or device hardware, software, or other
                  equipment or technology including, but without limitation
                  damage from any security breach or from any virus, bugs,
                  tampering, fraud, error, omission, interruption, defect, delay
                  in operation or transmission, computer line or network failure
                  or any other technical or other malfunction.
                </span>
              </p>
              <h2>
                <span>10. Limitation of Liability</span>
              </h2>
              <h3>
                <span>
                  My friend is a lawyer and says that you can’t exclude
                  liability for everything&#8230;
                </span>
              </h3>
              <p>
                <span>
                  Nothing in the Terms limits or excludes our liability for:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    death or personal injury caused by our proven negligence;
                  </span>
                </li>
                <li>
                  <span>our fraud or fraudulent misrepresentation; or</span>
                </li>
                <li>
                  <span>
                    any liability which cannot be limited or excluded by law.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  TO THE FULLEST EXTENT PERMITTED BY LAW, showunited GROUP
                  EXPRESSLY EXCLUDES, DISCLAIMS AND SHALL NOT BE LIABLE FOR:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    ALL CONDITIONS, REPRESENTATIONS, WARRANTIES AND OTHER TERMS
                    WHICH MIGHT OTHERWISE BE IMPLIED BY STATUTE, COMMON LAW OR
                    THE LAW OF EQUITY; AND
                  </span>
                </li>
                <li>
                  <span>
                    ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR
                    CONSEQUENTIAL DAMAGES (HOWEVER ARISING INCLUDING
                    NEGLIGENCE), OR ANY CLAIMS, CHARGES, DEMANDS, LIABILITIES,
                    LOSSES OR EXPENSES ARISING OUT OF LOSS OF USE, LOSS OF DATA,
                    LOSS CAUSED BY A COMPUTER OR ELECTRONIC VIRUS, LOSS OF
                    INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, WASTED
                    MANAGEMENT OR OFFICE TIME, BREACH OF CONTRACT OR CLAIMS OF
                    THIRD PARTIES OR OTHER LOSSES OF ANY KIND OR CHARACTER, EVEN
                    IF showunited GROUP HAS BEEN ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION WITH
                    THE USE OF showunited.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  IN NO EVENT WILL showunited GROUP’S TOTAL LIABILITY TO YOU IN
                  RESPECT OF showunited AND ANY LOSSES ARISING UNDER OR IN
                  CONNECTION WITH YOUR USE OF showunited OR THE TERMS, WHETHER
                  IN CONTRACT, TORT (INCLUDING NEGLIGENCE, BREACH OF STATUTORY
                  DUTY, OR OTHERWISE) EXCEED THE GREATER OF (I)$50 USD, OR (II)
                  THE AMOUNTS PAID BY YOU TO showunited GROUP DURING THE THREE
                  (3) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT(S) GIVING
                  RISE TO SUCH LIABILITY.
                </span>
              </p>
              <h2>
                <span>11. Indemnity</span>
              </h2>
              <h3>
                <span>
                  I forgot the rules about conduct on here and now someone is
                  threatening to sue YOU. What now?
                </span>
              </h3>
              <p>
                <span>
                  If we are sued as a result of your use of showunited then we
                  have the right to defend or settle the relevant claim as we
                  see fit. If we ask, you will co-operate fully and reasonably
                  as required by us in the defense of any relevant claim.
                </span>
              </p>
              <p>
                <span>
                  To the fullest extent permitted by applicable law, you agree
                  to hold harmless and indemnify us and our officers, directors,
                  employees, agents, representatives and licensors from and
                  against any third party claims, damages (actual and/or
                  consequential), actions, proceedings, demands, losses,
                  liabilities, costs and expenses (including reasonable
                  solicitors’ fees) suffered or reasonably incurred by us
                  arising as a result of, or in connection with, your access to
                  and use of showunited, your interactions with any other users
                  of showunited, the uploading or submission of Content to
                  showunited by you or your conduct, your violation of any law
                  or of any rights of any third party, and/or any other
                  negligent or willful misconduct by you (“Claim”). showunited
                  retains the exclusive right to settle, compromise and pay any
                  and all Claims or causes of action which are brought against
                  us without your prior consent.
                </span>
              </p>
              <h2>
                <span>12. Procedures for Making Claims of Infringement</span>
              </h2>
              <p>
                <span>
                  showunited Group has adopted the following policy towards
                  copyright infringement in accordance with the Digital
                  Millennium Copyright Act (the “DMCA”) of the United States. If
                  you believe any Content on showunited infringes upon your
                  intellectual property rights, please submit a notification
                  alleging such infringement (“DMCA Takedown Notice”) to
                  showunited Group’s Copyright Agent as identified below,
                  including the following:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    A physical or electronic signature of a person authorized to
                    act on behalf of the owner of an exclusive right that is
                    allegedly infringed;
                  </span>
                </li>
                <li>
                  <span>
                    Identification of the copyrighted work claimed to have been
                    infringed, or, if multiple copyrighted works at a single
                    online site are covered by a single notification, a
                    representative list of such works;
                  </span>
                </li>
                <li>
                  <span>
                    Identification of the material claimed to be infringing or
                    to be the subject of infringing activity and that is to be
                    removed or access disabled and information reasonably
                    sufficient to permit the service provider to locate the
                    material;
                  </span>
                </li>
                <li>
                  <span>
                    Information reasonably sufficient to permit the service
                    provider to contact you, such as an address, telephone
                    number, and, if available, an electronic mail;
                  </span>
                </li>
                <li>
                  <span>
                    A statement that you have a good faith belief that use of
                    the material in the manner complained of is not authorized
                    by the copyright owner, its agent, or the law; and
                  </span>
                </li>
                <li>
                  <span>
                    A statement that, under penalty of perjury, the information
                    in the notification is accurate and you are authorized to
                    act on behalf of the owner of the exclusive right that is
                    allegedly infringed.
                  </span>
                </li>
              </ol>
              <ol>
                <li></li>
              </ol>
              <h2>
                <span>14. Miscellaneous</span>
              </h2>
              <p>
                <span>
                  The Terms and any dispute or claim arising out of or in
                  connection with it or its subject matter (including
                  non-contractual disputes or claims) shall be governed by and
                  construed in accordance with the laws of the State of Texas,
                  without reference to conflicts of law principles. The United
                  Nations Convention on Contracts for the International Sales of
                  Goods does not apply to these Terms. In the event there is a
                  discrepancy between this English language version and any
                  translated copies of these Terms, the English version shall
                  prevail.
                </span>
              </p>
              <p>
                <span>
                  We reserve the right to modify, amend or change the Terms at
                  any time (a “Change”). If we do this then the Changes will be
                  posted on this page and we will indicate the effective date of
                  the updates at the bottom of the Terms. In certain
                  circumstances, we may send an email to you notifying you of
                  any Change, and/or require you to consent to such Change. You
                  should regularly check this page to take notice of any
                  Changes. If you do not accept any Change to the Terms, you
                  must stop using showunited immediately. Your continued use of
                  showunited following any Change constitutes your acceptance of
                  the Change and you will be legally bound by the new updated
                  Terms.
                </span>
              </p>
              <p>
                <span>
                  If, for any reason, any of the Terms are declared to be
                  illegal, invalid or otherwise unenforceable by a court of a
                  competent jurisdiction, then to the extent that term is
                  illegal, invalid or unenforceable, it shall be severed and
                  deleted from the Terms and the remainder of the Terms shall
                  survive, remain in full force and effect and continue to be
                  binding and enforceable.
                </span>
              </p>
              <p>
                <span>
                  The Terms set out the entire agreement and understanding
                  between us and you in relation to your use of showunited and
                  supersedes all previous agreements, representations and
                  arrangements between us (either oral or written). Nothing in
                  this clause shall limit or exclude any liability for
                  fraudulent misrepresentation.
                </span>
              </p>
              <p>
                <span>
                  No failure or delay by us in exercising any right, power or
                  privilege under the Terms shall operate as a waiver of such
                  right or acceptance of any variation of the Terms and nor
                  shall any single or partial exercise by either party of any
                  right, power or privilege preclude any further exercise of
                  that right or the exercise of any other right, power or
                  privilege.
                </span>
              </p>
              <p>
                <span>
                  Nothing in the Terms shall confer or purport to confer on any
                  other third party, any benefit or the right to enforce any
                  term of the Terms and the Contracts (Rights of Third Parties)
                  Act 1999 which shall not apply to the Terms.
                </span>
              </p>
              <p>
                <span>
                  These Terms, and your rights and obligations hereunder, may
                  not be assigned, subcontracted, delegated or otherwise
                  transferred by you without showunited Group’s prior written
                  consent, and any attempted assignment, subcontract,
                  delegation, or transfer in violation of the foregoing will be
                  null and void.
                </span>
              </p>
              <p>
                <span>
                  Where showunited Group requires that you provide an e-mail
                  address, you are responsible for providing showunited Group
                  with your most current e-mail address. In the event that the
                  last e-mail address you provided to showunited Group is not
                  valid, or for any reason is not capable of delivering to you
                  any notices required/ permitted by the Agreement, showunited
                  Group’s dispatch of the e-mail containing such notice will
                  nonetheless constitute effective notice. You may give notice
                  to showunited Group at the following address: showunited,
                  Attn.: E-mail Notice, P.O. Box 300940, Austin, Texas, 78703
                  (in addition, California and Ohio members may send a facsimile
                  to 512-696-1545). Such notice shall be deemed given when
                  received by showunited Group by letter delivered by nationally
                  recognized overnight delivery service or first class postage
                  prepaid mail at the above address.
                </span>
              </p>
              <p>
                <span>
                  The communications between you and showunited Group may take
                  place via electronic means, whether you use showunited or send
                  showunited Group emails, or whether showunited Group posts
                  notices in showunited or communicates with you via email. For
                  contractual purposes, you (a) consent to receive
                  communications from showunited Group in electronic form; and
                  (b) agree that all terms and conditions, agreements, notices,
                  disclosures, and other communications that showunited Group
                  provides to you electronically satisfy if it were to be in
                  writing. The foregoing does not affect your statutory rights,
                  including but not limited to the Electronic Signatures in
                  Global and National Commerce Act at 15 U.S.C. Sec. 7001 et.
                  seq.
                </span>
              </p>
              <p>
                <span>You represent and warrant that:</span>
              </p>
              <ol>
                <li>
                  <span>
                    you are not located in a country that is subject to a U.S.
                    Government embargo, or that has been designated by the U.S.
                    Government as a “terrorist supporting” country; and
                  </span>
                </li>
                <li>
                  <span>
                    you are not listed on any U.S. Government list of prohibited
                    or restricted parties.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  By using showunited, you agree and acknowledge that showunited
                  is a global app operating through servers located in a number
                  of countries around the world, including the United States. If
                  you live in a country with data protection laws, the storage
                  of your personal data may not provide you with the same
                  protections as you enjoy in your country of residence. By
                  submitting your personal information, or by choosing to
                  upgrade the services you use, or by making use of the
                  applications available on showunited, you agree to the
                  transfer of your personal information to, and storage and
                  processing of your personal information in, any such countries
                  and destinations.
                </span>
              </p>
              <p>
                <span>
                  If you are a California Resident the following applies to you:
                </span>
                <span>
                  {' '}
                  In accordance with California Civil Code §1789.3, you may
                  report complaints to the Complaint Assistance Unit of the
                  Division of Consumer Services of the California Department of
                  Consumer Affairs by contacting them in writing at 400 R
                  Street, Sacramento, CA 95814, or by telephone at (800)
                  952-5210.
                </span>
              </p>
              <h2>
                <span>15. THIRD PARTY APP STORE</span>
              </h2>
              <p>
                <span>
                  The following additional terms and conditions apply to you if
                  you download showunited from a Third Party Store. To the
                  extent that the other terms and conditions of these Terms are
                  less restrictive than, or otherwise conflict with, the terms
                  and conditions of this Section, the more restrictive or
                  conflicting terms and conditions in this Section will apply,
                  but solely with respect to showunited and the Third Party
                  Store. You acknowledge and agree that:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    These Terms are concluded solely between you and the
                    showunited Group and not with the providers of the Third
                    Party Store, and the showunited Group (and not the Third
                    Party Store providers) is solely responsible for showunited
                    and the content thereof. To the extent that these Terms
                    provide for usage rules for showunited which are less
                    restrictive or in conflict with the applicable terms of
                    service of the Third Party Store from which you obtain
                    showunited, the more restrictive or conflicting term of the
                    Third Party Store will take precedence and will apply.
                  </span>
                </li>
                <li>
                  <span>
                    The Third Party Store provider has no obligation whatsoever
                    to provide any maintenance and support services with respect
                    to showunited. The showunited Group is solely responsible
                    for any product warranties, whether express or implied by
                    law, to the extent not effectively disclaimed. The Third
                    Party Store provider will have no warranty obligation
                    whatsoever with respect to showunited, and any other claims,
                    losses, liabilities, damages, costs or expenses attributable
                    to any failure to conform to any warranty will be the sole
                    responsibility of the showunited Group.
                  </span>
                </li>
                <li>
                  <span>
                    The showunited Group, not the Third Party Store provider, is
                    responsible for addressing any claims you or any third party
                    may have relating to showunited or your possession and/or
                    use of showunited, including, but not limited to: (i)
                    product liability claims; (ii) any claim that showunited
                    fails to conform to any applicable legal or regulatory
                    requirement; (iii) claims arising under consumer protection
                    or similar legislation; and/or (iv) intellectual property
                    infringement claims.
                  </span>
                </li>
                <li>
                  <span>
                    The Third Party Store provider and its subsidiaries are
                    third party beneficiaries of these Terms, and, upon your
                    acceptance of these Terms, the Third Party Store provider
                    from whom you obtained showunited will have the right (and
                    will be deemed to have accepted the right) to enforce these
                    Terms against you as a third party beneficiary thereof.
                  </span>
                </li>
              </ol>
              <h2>
                <span>About us</span>
              </h2>
              <p>
                <span>
                  www.showunited.com is an app and site owned and operated by
                  showunited SL.
                </span>
              </p>
              <p>
                <span>
                  We are registered in Spain under NIF number 14305036w.
                </span>
              </p>
              <p>
                <span>
                  Our registered office is at Innovem, Carrer Punta Nati 8,
                  Office 1 &#8211; 07714 Mahon &#8211; SPAIN
                </span>
              </p>
              <h2>
                <span>Effective date</span>
              </h2>
              <p>
                <span>The Terms were last updated on: Nov 10, 2021.</span>
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </Col>
        </Row>
      </div>
      <Footer profileId={data.me.id} />
    </div>
  );
};

export default CookiePolicy;
