import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LineIcon from 'ui/theme/icons/LineIcon';
import { NavLink } from 'react-router-dom';
import './style.css';

interface ProfileMenuProps {
  profileId: string;
  user?: boolean;
  profileType: string;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ profileId, user, profileType }) => {
  const getBioClassname = () => {
    let className = 'd-flex flex-row justify-content-md-center justify-content-sm-end';
    if (profileType !== 'Artists')
      className += ' flex-grow-1';
    return className;
  };

  return(
    <Row className="menu-container">
      <div className="container-web d-flex justify-content-md-center">
        {profileType === 'Artists' && <Col md={4}  className="d-flex flex-row p-0  justify-content-sm-start justify-content-md-center">
          <NavLink exact activeClassName="active-link" to={user ? `/profile-user/${profileId}/measures` : `/profile/measures/${profileId}`}><LineIcon name="measure" size={30} /> <span className="menu-text">Size charts</span></NavLink>
        </Col>}
        <Col md={4} className="d-flex flex-row justify-content-center flex-grow-1">
          <NavLink exact activeClassName="active-link" to={user ? `/profile-user/${profileId}/multimedia` : `/profile/multimedia/${profileId}`}><LineIcon name="image" size={30} /> <span className="menu-text">Multimedia</span></NavLink>
        </Col>
        <Col md={4} className={getBioClassname()}>
          <NavLink exact activeClassName="active-link" to={user ? `/profile-user/${profileId}/bio`: `/profile/bio/${profileId}`}><LineIcon name="certificate" size={30} /> <span className="menu-text">Bio</span></NavLink>
        </Col>
      </div>
    </Row>
  );
};
export default ProfileMenu;
