import React from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'ui/MyProfile/Login/images/logo-white.svg';
import PrimaryButton from '../../components/form/PrimaryButton';
import FormInput from '../../components/form/FormInput';
import { Link } from 'react-router-dom';
import './style.css';

interface LoginUIProps {
  email: string,
  emailError: string;
  password: string;
  passwordError: string;
  handleEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  serverError: any;
  tryLogin: any;
  isLoading: boolean;
  onKeyDownPassword: (e: any) => void;
}

const LoginUI: React.FC<LoginUIProps> = ({ email, emailError, password, passwordError, serverError, tryLogin, isLoading, handleEmail, handlePassword, onKeyDownPassword }) => {
  return(
    <div className="container-login">
      <Row>
        <Col className="text-center mt-md-5">
          <img alt="logo" src={logo} className="logo" />
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-md-3 mb-mb-5 login-subtitle">
          The unique show-business<br/> oriented profesional<br/> networking platform
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login">
            <FormInput type="text" value={email} onChange={handleEmail} placeholder="Enter email" error={emailError}/>
            <FormInput type="password" value={password} onChange={handlePassword} placeholder="Enter password" error={passwordError} onKeyDown={onKeyDownPassword}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login mt-2">
            <PrimaryButton label="Login" loading={isLoading} onClick={tryLogin} style="mt-5" />
            {serverError && <div className="login-server-error">{serverError}</div>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <Link className="forgot-password" to={'/forgot-password'}>Forgot password?</Link>
        </Col>
      </Row>
      <Row>
        <Col  md={{ span: 4, offset: 4 }}>
          <div className="terms-text mt-md-5">
            By continuing, you&apos;re confirming that you&apos;ve read and agree to our
            <a className="terms-link" href="https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/" target="BLANK"> Terms and Conditions</a>,
            <a className="terms-link" href="https://www.showunited.com/showunited-privacy-policy/" target="BLANK"> Privacy Policy</a> and
            <a className="terms-link" href="https://www.showunited.com/cookies-policy/" target="BLANK"> Cookies Policy</a>.
          </div>
        </Col>
      </Row>
    </div>
  );
};


export default LoginUI;
