import React from 'react';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from 'infrastructure/graphql/query';
import Header from 'ui/components/screen/Header';
import Footer from 'ui/components/screen/Footer';
import LoadingScreen from 'ui/components/LoadingScreen';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const PrivacyPolicy: React.FC = () => {
  const { loading, data, error } = useQuery(QUERY_ME, {
    fetchPolicy: 'network-only',
  });
  if (loading) return <LoadingScreen />;
  if (error) return <span>ERROR OCURRED</span>;
  return (
    <div className='container-app'>
      <div className='container-fluid content'>
        <Header data={data.me} me={data.me} />
        <Row className='container-dashboard container-web'>
          <Col>
            <div>
              <h1>
                <span>Show United Privacy Policy</span>
              </h1>
              <p>
                <span>
                  Welcome to the Showunited Privacy Policy (“Policy”)! This
                  explains how we collect, store, protect and share your
                  information, and with whom we share it. We suggest you read
                  this in conjunction with our{' '}
                </span>
                <strong>
                  <a href='https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/'>
                    Terms and Conditions of Use
                  </a>
                  .
                </strong>
              </p>
              <p>
                <span>
                  Whilst you’re enjoying the Showunited mobile application
                  (including the desktop version) (“App”), our websites and
                  microsites (such as Showunited.com) or using our digital
                  products and services (such as our competitions or surveys)
                  (together, referred to in this Privacy Policy as our “Sites”),
                  we collect some information about you. In addition, you may
                  choose to use the App or Sites to share information with other
                  Showunited members including your friends and contacts
                  (“Users”). We may also need to share your information
                  sometimes.
                </span>
              </p>
              <p>
                <span>
                  Showunited’s App and Sites are global, and your information
                  will be sent to and used in the United States and the EU
                  regardless of the country you reside in. This Policy explains
                  how we protect your personal data when we transfer it
                  overseas, so please read very carefully!
                </span>
              </p>
              <h2>
                <span>Who we are</span>
              </h2>
              <p>
                <span>
                  The following Showunited Group entities act as the data
                  controllers of the personal information collected and
                  processed through the Showunited App and Sites.
                </span>
              </p>
              <p>
                <span>
                  Showunited has designated a Data Protection Officer that can
                  be reached by emailing dpo@showunited.com or by post at the
                  following address: Carrer Punta Nati 8, Office 1 &#8211; 07714
                  Mahon &#8211; SPAIN
                </span>
              </p>
              <h2>
                <span>1. COLLECTION OF INFORMATION</span>
              </h2>
              <h3>
                <span>Registration information</span>
              </h3>
              <p>
                <span>
                  When you download the App and create an account (“Account”),
                  we may collect certain information (“Registration
                  Information”) about you, such as:
                </span>
              </p>
              <ul>
                <li>
                  <span>Name;</span>
                </li>
                <li>
                  <span>Username;</span>
                </li>
                <li>
                  <span>Email address</span>
                </li>
                <li>
                  <span>Mobile number</span>
                </li>
                <li>
                  <span>Gender identity;</span>
                </li>
                <li>
                  <span>Date of birth;</span>
                </li>
                <li>
                  <span>Photographs;</span>
                </li>
                <li>
                  <span>Location, and</span>
                </li>
                <li>
                  <span>
                    Login information for social media accounts that you connect
                    to your Showunited Account (this could include, for example,
                    your Facebook and Instagram accounts). For more information
                    about this, see “Linking other accounts to Showunited” in
                    section 7 below.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  You will also be required to create a password in connection
                  with the registration of your Account. Once you register, you
                  will be able to review and change this information at any time
                  just by logging in to Showunited (other than your location,
                  which, if you have given Showunited access to your location in
                  your device settings, is automatically updated based on the
                  location of your device) or contacting Showunited’s{' '}
                </span>
                <strong>
                  <a href='https://showunited.com/contact'>
                    Customer Support Team
                  </a>
                </strong>
                <span>
                  . It is your responsibility to ensure that your account
                  details are kept up to date. If your phone number changes,
                  please ensure that you update this in your account.
                </span>
              </p>
              <p>
                <span>
                  The information we collect helps to enhance the App and verify
                  our Users (robots are not welcome!). Registration Information
                  such as your sexual preference, name and username may be
                  visible to other Users who view your profile page.
                </span>
              </p>
              <p>
                <span>
                  For users who are California residents, the data we may
                  collect falls within the following categories of “personal
                  information,” as defined by the California Consumer Privacy
                  Act (CCPA):
                </span>
              </p>
              <ul>
                <li>
                  <span>A. Identifiers, such as name and location;</span>
                </li>
                <li>
                  <span>
                    B. Personal information, as defined in the California
                    customer records law, such as contact (including email and
                    telephone number) and financial information;
                  </span>
                </li>
                <li>
                  <span>
                    C. Characteristics of protected classifications under
                    California or federal law (if you choose to provide them),
                    such as age, gender identity, marital status, sexual
                    orientation, race, ancestry, national origin, religion, and
                    medical conditions;
                  </span>
                </li>
                <li>
                  <span>
                    D. Commercial information, such as transaction information
                    and purchase history;
                  </span>
                </li>
                <li>
                  <span>
                    E. Biometric information, such as faceprint (not relevant
                    here);
                  </span>
                </li>
                <li>
                  <span>
                    F. Internet or network activity information, such as
                    browsing history and interactions with our Sites and App;
                  </span>
                </li>
                <li>
                  <span>
                    G. Geolocation data, such as mobile device location;
                  </span>
                </li>
                <li>
                  <span>
                    H. Audio, electronic, visual and similar information, such
                    as photos and videos;
                  </span>
                </li>
                <li>
                  <span>
                    I. Professional or employment-related information, such as
                    work history and prior employer;
                  </span>
                </li>
                <li>
                  <span>J. Non-public education information; and</span>
                </li>
                <li>
                  <span>
                    K. Inferences drawn from any of the personal information
                    listed above to create a profile or summary about, for
                    example, an individual’s preferences and characteristics.
                  </span>
                </li>
              </ul>
              <h3>
                <span>Profile Information</span>
              </h3>
              <p>
                <span>
                  We recommend and encourage you (and all our members) to think
                  carefully about the information you disclose about yourself.
                  We also do not recommend that you put email addresses, URLs,
                  instant messaging details, phone numbers, full names or
                  addresses, credit card details, national identity numbers,
                  drivers’ licence details and other sensitive information which
                  is open to abuse and misuse on your profile.
                </span>
              </p>
              <p>
                <span>
                  When you post information about yourself or use the messaging
                  function to communicate with other Users, the amount of
                  personal information you share is at your own risk. Please see
                  Section 4 below for more information on who can access what
                  you post on Showunited.
                </span>
              </p>
              <h3>
                <span>Purchases Information</span>
              </h3>
              <p>
                <span>
                  If you decide to purchase any of our premium services, we will
                  process your payment information and retain this securely for
                  the prevention of fraud and for audit/tax purposes.
                </span>
              </p>
              <p>
                <span>
                  Showunited uses automated decisions to prevent fraudulent
                  payment transactions being processed as part of its anti-fraud
                  procedures. In order to do this, our systems check payment
                  transactions for behaviour that indicates breaches of our{' '}
                </span>
                <strong>
                  <a href='https://showunited.com'>Website</a>
                </strong>
                <span>
                  . If a transaction meets certain criteria that demonstrate
                  that the Terms and Conditions of Use are likely to have been
                  breached and the transaction is likely to be fraudulent, the
                  relevant transaction may automatically be blocked. Where a
                  transaction is blocked, the user will be notified that their
                  transaction cannot be processed and affected Users can contact
                  Showunited to contest the decision.
                </span>
              </p>
              <p>
                <span>
                  Please note that if your account is blocked for any reason,
                  any account(s) that you have on any other Showunited Group
                  platforms/applications, such as Bumble, may also be blocked as
                  part of our anti-spam and anti-fraud procedures.
                </span>
              </p>
              <h3>
                <span>Geolocation Information</span>
              </h3>
              <p>
                <span>
                  If you have given Showunited access to your location in your
                  device settings, when you use your mobile, we will collect
                  information about WiFi access points as well as other location
                  information about your longitude and latitude and may save
                  your device’s coordinates to offer certain features to you.
                  This information helps us identify your physical location and
                  we may use it to personalise the App and make it easier for
                  you to interact with other Users, by enabling the general
                  locality information to be displayed to Users seeing your
                  profile and showing you the profiles of other Users who are
                  near you.
                </span>
              </p>
              <p>
                <span>
                  If you have given Showunited access to your location services,
                  but wish to turn them off, you can do so by the following
                  methods:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    iPhone app — settings, privacy, location services,
                    Showunited
                  </span>
                </li>
                <li>
                  <span>
                    Android — settings, location, Showunited, permissions,
                    location
                  </span>
                </li>
              </ol>
              <h3>
                <span>Device Information</span>
              </h3>
              <p>
                <span>
                  We may collect information about your device when you use the
                  App including the unique device identifier, device model,
                  operating system, and MAC address, for a number of purposes,
                  as set out here. In addition, if you permit us to do so, the
                  App may access your device’s address book solely in order to
                  add someone to your contacts.
                </span>
              </p>
              <h3>
                <span>Links</span>
              </h3>
              <p>
                <span>
                  We may keep track of how you interact with links available on
                  Showunited, including third party services by redirecting
                  clicks or through other means. We may share aggregate click
                  statistics such as how many times a particular link was
                  clicked on.
                </span>
              </p>
              <h3>
                <span>Honesty Club and other Contributions</span>
              </h3>
              <p>
                <span>
                  From time to time, we run surveys for research purposes and we
                  may contact you to find out if you would like to take part. We
                  may also contact you to find out if you would like to provide
                  feedback, a testimonial or take part in marketing campaigns
                  (for example, if you let us know that you have found a match
                  on the App, we may contact you to ask if you would be happy to
                  feature in advertising for Showunited). Such surveys and
                  marketing campaigns are optional and more information will be
                  provided at the point of contact. If you do not wish to be
                  contacted to take part in a survey or marketing campaign,
                  please contact our Customer Support Team via our{' '}
                </span>
                <strong>
                  <a href='https://showunited.com/contact'>Contact website</a>.
                </strong>
              </p>
              <h3>
                <span>When you contact Customer Support</span>
              </h3>
              <p>
                <span>If you contact our Customer Support team via </span>
                <strong>
                  <a href='https://showunited.com/contact'>Contact website</a>
                </strong>
                <span>
                  <strong>,</strong> we will receive your email address, and may
                  track your IP address as well as the information you send to
                  us to help resolve your query. We will keep records of our
                  communications with you, including any complaints that we
                  receive from you about other users (and from other users about
                  you) for 6 years after deletion of your account.
                </span>
              </p>
              <h3>
                <span>Cookies and similar technologies</span>
              </h3>
              <p>
                <span>
                  When you visit our Sites or when you use our App, we may
                  collect personal data from you automatically by using cookies
                  or similar technologies. A cookie is a small file that can be
                  placed on your device or browser that allows us to recognise
                  and remember you. If you would like to find out more about
                  cookies, including how we use them and what choices are
                  available to you, please refer to our Cookie Policy.
                </span>
              </p>
              <h2>
                <span>2. USE OF YOUR INFORMATION</span>
              </h2>
              <p>
                <span>
                  Our main goal is to ensure your experience on Showunited is an
                  enjoyable one and in order to deliver it to you, we may use
                  your Registration and other information to:
                </span>
              </p>
              <ul>
                <li>
                  <span>offer you our services and features;</span>
                </li>
                <li>
                  <span>
                    contact you with information about the App (e.g. updates and
                    new features);
                  </span>
                </li>
                <li>
                  <span>
                    to personalise the App/Sites and the content we deliver to
                    you;
                  </span>
                </li>
                <li>
                  <span>
                    conduct research and analytics about how you use and
                    interact with the App/Sites;
                  </span>
                </li>
                <li>
                  <span>
                    resolve disputes, troubleshoot problems and enforce our{' '}
                  </span>
                  <strong>
                    <a href='https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/'>
                      Terms and Conditions of Use
                    </a>
                    .
                  </strong>
                </li>
                <li>
                  <span>
                    investigate fraud, protect our legal rights, and to enforce
                    our{' '}
                  </span>
                  <strong>
                    <a href='https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/'>
                      Terms and Conditions of Use
                    </a>
                    .
                  </strong>
                </li>
                <li>
                  <span>
                    to send you information about the promotions and offers we
                    have available &#8211; if you’ve signed up for our
                    newsletters or otherwise told us it’s ok (you can withdraw
                    this consent at any time via Settings in the App or by using
                    the opt-out mechanisms and links provided in each message);
                    and
                  </span>
                </li>
                <li>
                  <span>protect our Users and third parties from harm.</span>
                </li>
              </ul>
              <h3>
                <span>Moderation Practices</span>
              </h3>
              <p>
                <span>
                  We use a combination of automated systems and a team of
                  moderators to monitor and review accounts (including photos
                  and any other information uploaded onto user profiles) and
                  messages for content that indicates breaches of our{' '}
                </span>
                <strong>
                  <a href='https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/'>
                    Terms and Conditions of Use
                  </a>
                </strong>
                <span>
                  <strong>.</strong> If an account or message meets certain
                  criteria that demonstrate that the{' '}
                </span>
                <strong>
                  <a href='https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/'>
                    Terms and Conditions of Use
                  </a>
                </strong>
                <span>
                  <strong>.</strong> are likely to have been breached, the
                  relevant account will be subject to a warning and the user’s
                  access restricted and/or blocked. Affected Users can contact
                  Showunited to contest the decision.
                </span>
              </p>
              <p>
                <span>
                  If you post anything that is inconsistent with our Terms and
                  Conditions of Use, we reserve the right to terminate or
                  restrict access to your Account.
                </span>
              </p>
              <h3>
                <span>Lawful Basis</span>
              </h3>
              <p>
                <span>
                  Under EU and UK data protection laws, we are required to tell
                  you our lawful basis for using your data and we have set this
                  out in the table below. Where the legal basis is consent, you
                  can withdraw consent at any time. Where the legal basis is
                  legitimate interests, you have a right to object to our use of
                  your data. We explain in the relevant sections in this Policy
                  how you can withdraw consent or opt-out of certain data uses
                  (where applicable).
                </span>
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Purpose for which data is used</b>
                    </td>
                    <td>
                      <b>Data</b>
                    </td>
                    <td>
                      <b>Source</b>
                    </td>
                    <td>
                      <b>Legal basis</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To provide you with the Showunited social networking
                        service
                      </span>
                    </td>
                    <td>
                      <span>
                        Name, email address, date of birth, location (CCPA
                        Categories A and B)
                      </span>
                    </td>
                    <td>
                      <span>
                        You provide your name, email address and date of birth
                        to us. We obtain location data from the device that you
                        use to access the service
                      </span>
                    </td>
                    <td>
                      <span>Contractual necessity</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To facilitate networking opportunities on the Showunited
                        site
                      </span>
                    </td>
                    <td>
                      <span>
                        Optional information that you choose to provide in your
                        profile, including through profile verification, which
                        may include information about your sexual preferences,
                        non-binary gender, religion, ethnic background, photos
                        etc. (CCPA Categories C, H, I, J)
                      </span>
                    </td>
                    <td>
                      <span>You provide this information to us</span>
                    </td>
                    <td>
                      <span>Consent</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To verify your identity and prevent fraud and to ensure
                        the safety and security of Users
                      </span>
                    </td>
                    <td>
                      <span>
                        Phone number and if requested, photo provided as part of
                        profile verification (CCPA Category B and H)
                      </span>
                    </td>
                    <td>
                      <span>You provide this information to us</span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to ensure that accounts are not set up fraudulently and
                        to safeguard Users of the site
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To take payment for premium services (not applicable for
                        Users making payments via the Apple App Store)
                      </span>
                    </td>
                    <td>
                      <span>
                        Payment card details (CCPA Categories B and D)
                      </span>
                    </td>
                    <td>
                      <span>You provide this information to us</span>
                    </td>
                    <td>
                      <span>Contractual necessity</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To allow our Advertising Partners to serve targeted
                        advertisements on our App and Sites (this includes
                        rewarded video ads)
                      </span>
                    </td>
                    <td>
                      <span>
                        Advertising identifier associated with your device
                        (Device ID), age, gender, and IP address (and your
                        estimated locations based on your IP address) (CCPA
                        Categories A, C and G)
                      </span>
                    </td>
                    <td>
                      <span>
                        We obtain age and gender from you, and Device ID and IP
                        address from the device that you use to access the
                        service
                      </span>
                    </td>
                    <td>
                      <span>
                        Consent – as indicated by you in your Privacy
                        Settings/Cookie Settings preferences and via your
                        browser or device privacy preferences (where required by
                        your device manufacturer (for example Apple devices
                        using iOS 14.5)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To serve advertisements to Users of our Sites/App on
                        third party networks and measure the effectiveness of
                        such ads
                      </span>
                    </td>
                    <td>
                      <span>
                        Data about your visit to our Sites or App and action
                        taken on those (for example if you downloaded our App or
                        created an account with Showunited) IP address (and your
                        estimated location based on your IP address) age,
                        gender, Device ID (CCPA Categories A, C, G, F and K)
                      </span>
                    </td>
                    <td>
                      <span>
                        We obtain age and gender from you and we obtain other
                        information from the device or browser that you use to
                        access the service
                      </span>
                    </td>
                    <td>
                      <span>
                        Consent – as indicated by you in your Privacy
                        Settings/Cookie Settings preferences and via your
                        browser or device privacy preferences (where required by
                        your device manufacturer (for example Apple devices
                        using iOS 14.5)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To serve promo cards and advertisements on the App
                      </span>
                    </td>
                    <td>
                      <span>
                        Location, gender, age, and information that you have
                        optionally provided us with via your profile (CCPA
                        Categories A, C and G)
                      </span>
                    </td>
                    <td>
                      <span>
                        We obtain age, gender and profile information from you,
                        and location data from the device that you use to access
                        the service
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to target advertisements so that Users see relevant
                        advertisements and to allow us to generate income from
                        advertising revenue
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To send you marketing information about our offers and
                        services
                      </span>
                    </td>
                    <td>
                      <span>
                        Email address and mobile phone number (CCPA Category B)
                      </span>
                    </td>
                    <td>
                      <span>You provide this information to us</span>
                    </td>
                    <td>
                      <span>Consent</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To contact you in order to run surveys for research
                        purposes and to obtain feedback, and to find out if you
                        want to take part in marketing campaigns
                      </span>
                    </td>
                    <td>
                      <span>
                        Email address and mobile phone number (CCPA Category B)
                      </span>
                    </td>
                    <td>
                      <span>You provide this information to us</span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to carry out research so that we can further develop the
                        app and improve the service
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To enable Users to create and enhance their Showunited
                        profile and log into the app via third party accounts
                      </span>
                    </td>
                    <td>
                      <span>
                        Data from Facebook and other third party accounts (see
                        section 7 of this policy for more details) (CCPA
                        Categories A, B, C, and H)
                      </span>
                    </td>
                    <td>
                      <span>
                        We obtain this information from Facebook or the
                        providers of any other accounts you use to log in or
                        connect with your Showunited account
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to facilitate access to our services
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>To show you other Users near you</span>
                    </td>
                    <td>
                      <span>
                        WiFi access points and location data when you use the
                        App (if you give us permission) (CCPA Category G)
                      </span>
                    </td>
                    <td>
                      <span>
                        We obtain this information from the device that you use
                        to access the service (if you give us permission)
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to provide this functionality as part of the services
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To carry out research and analysis to help us improve
                        the App
                      </span>
                    </td>
                    <td>
                      <span>
                        Log and usage data, including IP address, browser type,
                        referring domain, pages accessed, mobile carrier and
                        search terms, images and videos (CCPA Categories F and
                        H)
                      </span>
                    </td>
                    <td>
                      <span>
                        You provide photos and videos to use. We obtain the log
                        and usage information from the device that you use to
                        access the service
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our interests to analyse
                        the way in which Users are accessing and using our
                        services so that we can further develop the App,
                        implement security measures and improve the service
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To respond to correspondence and queries that you submit
                        to us, including social media queries
                      </span>
                    </td>
                    <td>
                      <span>
                        Email address, IP address, social media name, phone
                        number (CCPA Categories B and F)
                      </span>
                    </td>
                    <td>
                      <span>
                        You provide your email address, social media name and
                        phone number to us when you contact us and we obtain
                        your IP address from the device that you use to contact
                        us
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to respond to your queries to ensure that we provide a
                        good service to Users and troubleshoot problems
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To block accounts as part of our anti-spam procedures
                      </span>
                    </td>
                    <td>
                      <span>
                        Email address, phone number, IP address and IP session
                        information, social network ID, username, user agent
                        string (CCPA Categories B and F)
                      </span>
                    </td>
                    <td>
                      <span>
                        You provide your email address, phone number and
                        username to us. We obtain the other information from the
                        device that you use to access the service
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to prevent unauthorised behaviour and to maintain the
                        safety and security of our services
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To investigate and block Users for reported
                        infringements of our Terms and Conditions of Use
                      </span>
                    </td>
                    <td>
                      <span>
                        Name and user registration details, profile information,
                        content of messages and photographs (CCPA Categories A,
                        B, C, E and H)
                      </span>
                    </td>
                    <td>
                      <span>
                        You provide your name, profile content and activities on
                        the App to us.
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to prevent unauthorised behaviour to maintain the safety
                        and integrity of our services.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To block payment transactions as part of our anti-fraud
                        procedures
                      </span>
                    </td>
                    <td>
                      <span>
                        Name, IP address, email address, mobile number,
                        cardholder name, payments received, type of payment,
                        user ID, country (CCPA Categories, A, B, and D)
                      </span>
                    </td>
                    <td>
                      <span>
                        You provide your name, email address, phone number and
                        card details to us. We obtain your IP address from the
                        device that you use to contact us. We obtain your
                        payment information from your interactions with our
                        service
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to prevent fraudulent transactions and to maintain the
                        security of our services
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To enable video and audio call functions and the sending
                        of images and video
                      </span>
                    </td>
                    <td>
                      <span>
                        Video and audio call usage data, images and video (CCPA
                        Categories F and H)
                      </span>
                    </td>
                    <td>
                      <span>
                        We obtain this information from the device you use to
                        access the service
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests &#8211; it is in our legitimate
                        interest to provide these functionalities as part of the
                        services.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To build a more accurate profile of you, which helps us
                        with matches and to personalise the service
                      </span>
                    </td>
                    <td>
                      <span>
                        The apps installed on your device (Android only) (CCPA
                        Categories F and K)
                      </span>
                    </td>
                    <td>
                      <span>
                        We obtain this information from the device that you use
                        to access our service
                      </span>
                    </td>
                    <td>
                      <span>Consent</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        To defend legal claims, protect legal rights and to
                        protect people from harm
                      </span>
                    </td>
                    <td>
                      <span>
                        This could include any information that is relevant to
                        the issue
                      </span>
                    </td>
                    <td>
                      <span>
                        This information may be obtained directly from you, from
                        your device or from third parties, depending on the
                        information involved
                      </span>
                    </td>
                    <td>
                      <span>
                        Legitimate interests – it is in our legitimate interests
                        to protect our legal rights, defend legal claims and to
                        protect our Users and third parties from harm
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>
                <span>3. DISCLOSURE OF INFORMATION</span>
              </h2>
              <p>
                <span>
                  Our policy is to not disclose your personal data, except in
                  the limited circumstances described below:
                </span>
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Circumstances where data may be disclosed</b>
                    </td>
                    <td>
                      <b>Disclosed data</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Service Providers &#8211; We engage certain trusted
                        third parties to perform functions and provide services
                        to us. We may share your personal data with these third
                        parties, but only for the purposes of performing these
                        functions and providing such services. More information
                        about this is available directly below
                      </span>
                    </td>
                    <td>
                      <span>
                        This could include all data, including all CCPA
                        Categories listed above
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Moderators &#8211; to monitor activity on the site/apps
                        and approve content
                      </span>
                    </td>
                    <td>
                      <span>
                        Name and user registration details, profile information,
                        content of messages and photographs (CCPA Categories A,
                        B, C, E, and H)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Advertising Partners &#8211; our Advertising Partners
                        serve targeted advertisements on our App and Sites (this
                        includes rewarded video ads) – more information on this
                        is available below
                      </span>
                    </td>
                    <td>
                      <span>
                        Advertising identifier associated with your device
                        (Device ID), age, gender, and IP address (and your
                        estimated locations based on your IP address) (CCPA
                        Categories A, C and G)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Law and Harm &#8211; As we mentioned in the </span>
                      <strong>
                        <a href='https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/'>
                          Terms and Conditions of Use
                        </a>
                      </strong>
                      <span>
                        ., we think it is very important that all Showunited
                        members behave whilst using the App. We will cooperate
                        with all third parties to enforce their intellectual
                        property or other rights. We will also cooperate with
                        law enforcement enquiries from within or outside your
                        country of residence where we are required to by law,
                        where there is an investigation into alleged criminal
                        behaviour or to protect the vital interests of a person.
                        This may include preserving or disclosing any of your
                        information, including your Registration Information, if
                        we believe in good faith that it is necessary to comply
                        with a law or regulation, or when we believe that
                        disclosure is necessary to comply with a judicial
                        proceeding, court order, or legal request; to protect
                        the safety of any person; to address fraud, security or
                        technical issues e.g. through anti-spam providers to
                        protect the service from criminal activity or to protect
                        our rights or property or those of third parties. In
                        such cases we may raise or waive any legal objection or
                        right available to us.
                      </span>
                    </td>
                    <td>
                      <span>
                        This could include any personal data that Showunited
                        holds about you, depending on the nature of the request
                        or the issue that we are dealing with, including all
                        CCPA Categories listed above
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Marketing Services Providers &#8211; To help us serve
                        marketing and advertising on third party websites and
                        applications and measure the effectiveness of our
                        advertising campaigns. More information on this is
                        available below.
                      </span>
                    </td>
                    <td>
                      <span>
                        Advertising identifier associated with your device
                        (Device ID), estimated location (based on your IP
                        address), age, gender and data about your visit to our
                        Sites or App and action taken on those (for example if
                        you downloaded our App or created an account with our
                        App) (CCPA Categories B, C, G, F and K)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Payment processing and telecommunications companies
                        &#8211; To facilitate payments for our premium services
                      </span>
                    </td>
                    <td>
                      <span>
                        Cardholder name, cardholder address, card number,
                        payment amount, transaction date/time (CCPA Categories
                        A, B, and D)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Business Transfers &#8211; In the event that a
                        Showunited Group entity or any of its affiliates
                        undergoes a business transition or change of ownership,
                        such as a merger, acquisition by another company,
                        re-organisation, or sale of all or a portion of its
                        assets, or in the event of insolvency or administration,
                        we may be required to disclose your personal data to a
                        purchaser of all or part of the business or to an
                        insolvency practitioner
                      </span>
                    </td>
                    <td>
                      <span>
                        This could include all personal data that Showunited
                        holds about you, including all CCPA Categories listed
                        above
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Anti-Spam and Anti-Fraud &#8211; Your data may be shared
                        with other Showunited Group companies, for example, to
                        block accounts and suspected fraudulent payment
                        transactions as part of our anti-spam and anti-fraud
                        procedures.
                      </span>
                    </td>
                    <td>
                      <span>
                        This could include email address, phone number, IP
                        address and IP session information, social network ID,
                        username, user agent string, and transaction and payment
                        data (CCPA Categories B, F and D)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        Aggregated Information &#8211; We may share aggregated
                        information with third parties that includes your
                        personal data (but which doesn’t identify you directly)
                        together with other information including log data for
                        industry analysis and demographic profiling.
                      </span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <h2>
                <span>MORE INFORMATION ABOUT DISCLOSURES</span>
              </h2>
              <h3>
                <span>Sharing your personal data with Service Providers</span>
              </h3>
              <p>
                <span>
                  We engage certain trusted third parties to perform functions
                  and provide services to us (“Service Providers”). The
                  suppliers with which Showunited shares User personal data vary
                  depending on a variety of factors, such as which of our Apps
                  and Sites a User engages with. For example, to provide our
                  services to Showunited App Users, we typically use the
                  following suppliers:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    Billing services &#8211; to allow customers to purchase paid
                    features of our App (for example, Google Play)
                  </span>
                </li>
                <li>
                  <span>
                    Authentication services &#8211; to allow customers to
                    authenticate their account (for example, Twilio)
                  </span>
                </li>
                <li>
                  <span>
                    Social media providers &#8211; to allow customers to
                    create/connect their Showunited account with their
                    account(s) on such platforms (for example Facebook or
                    Instagram)
                  </span>
                </li>
                <li>
                  <span>
                    Product improvement and market research &#8211; we use third
                    party platforms (such as Typeform) and agencies (such as
                    Kantar) to carry out customer surveys and market research to
                    improve our products and services
                  </span>
                </li>
                <li>
                  <span>
                    Growth marketing services &#8211; to allow us to market and
                    advertise Showunited to potential customers (for example
                    AppsFlyer and Facebook)
                  </span>
                </li>
                <li>
                  <span>
                    IT services &#8211; some of the third-party software
                    providers used in the operation of our business may process
                    customers’ personal data (for example, if a customer
                    contacts us via social media with a support inquiry, their
                    inquiry is processed by our community management software
                    provider Khoros)
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  We carry out due diligence on all Service Providers we engage
                  to ensure they have adequate data protection and information
                  security measures in place and only provide them with the
                  personal data necessary to the service they are providing. Our
                  suppliers are also subject to extensive obligations under our
                  contractual arrangements, including strict data retention
                  limits.
                </span>
              </p>
              <h3>
                <span>
                  Sharing your personal data with Marketing Service Providers
                  and Advertising Partners
                </span>
              </h3>
              <p>
                <span>
                  We may partner with advertising publishers to place third
                  party adverts on our App and Sites (‘Advertising Partners’).
                  We also partner with providers of marketing services (such as
                  Facebook for example) (‘Marketing Services Providers’) to help
                  us market and advertise our App and services on third party
                  websites and applications and measure the effectiveness of our
                  advertising campaigns. For example:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    to exclude you from advertising campaigns aimed at finding
                    new users, if you already have a Showunited account;
                  </span>
                </li>
                <li>
                  <span>
                    to show Showunited adverts to users who have visited the
                    Showunited App/Sites but haven’t yet created a Showunited
                    account;
                  </span>
                </li>
                <li>
                  <span>
                    to show Showunited adverts to existing Showunited Users to
                    encourage them to use the service (for example, when we
                    release new features for the App, we may advertise these
                    features to existing Users); or
                  </span>
                </li>
                <li>
                  <span>
                    to create an audience for our advertisements of other
                    potential users who have similar characteristics to you
                    based on the information the Marketing Service Providers
                    hold about you (also known as a Lookalike Audience); or
                  </span>
                </li>
                <li>
                  <span>
                    to include you in a ‘custom audience’ that will receive
                    Showunited advertising content (a custom audience is
                    essentially a list of people who we think are most likely to
                    be interested in a particular advertisement).
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  We share a limited amount of your personal data with companies
                  that help us with marketing and advertising namely:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    the advertising identifier associated with your device (this
                    is a random number assigned by your mobile device
                    manufacturer (for example Apple or Google) to your device to
                    help advertisers (including the manufacturer), know when an
                    ad has been viewed or clicked on in the App or sites, and
                    when an ad causes a “conversion” (for example downloading
                    the app advertised to you))
                  </span>
                </li>
                <li>
                  <span>
                    your estimated location (based on your IP address)
                  </span>
                </li>
                <li>
                  <span>age and gender</span>
                </li>
                <li>
                  <span>
                    data about your visit to our Sites or App and action taken
                    on those (for example if you download our App or created an
                    Account with our App)
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  For more information about how we use cookies and other
                  tracking technologies, including how you can set and manage
                  your preferences with regards to such technologies, please see
                  our Cookie Policy.
                </span>
              </p>
              <p>
                <span>
                  Some platforms require user consent before Showunited is
                  permitted to use data gained through the platform for
                  advertising purposes and where it is the case, Showunited will
                  only use this data where the necessary consent has been
                  obtained. Through your device’s security settings, you also
                  have the option to prevent or limit device identifiers being
                  shared with third party advertisers and what use is made of
                  the device identifiers. If you would like more information
                  about this practice and to know your choices about not having
                  this information used by these companies, please visit{' '}
                </span>
                <strong>
                  <a href='https://optout.networkadvertising.org/?c=1'>
                    this page
                  </a>
                  .
                </strong>
              </p>
              <p>
                <span>
                  In some cases, these third parties will also use the data that
                  they collect for their own purposes, for example they may
                  aggregate your data with other data they hold and use this to
                  inform advertising related services provided to other clients.
                </span>
              </p>
              <h3>
                <span>Sharing with Facebook</span>
              </h3>
              <p>
                <span>
                  Where we share data with Facebook as our Marketing Service
                  Provider (including via the Facebook “Like” function), we are
                  Joint Data Controllers with Facebook Ireland for this
                  processing. This arrangement means that Showunited has to
                  provide you this notice, but you should contact Facebook if
                  you wish to exercise your data protection rights. Further
                  information, including how Facebook enables you to exercise
                  your data protection rights, and subsequently processes your
                  information as an independent data controller can be found in{' '}
                </span>
                <a href='https://en-gb.facebook.com/policy.php'>
                  <span>Facebook Ireland’s Data Policy</span>
                </a>
                <span>
                  . If you want more information relating to the nature of the
                  arrangement we have in place with Facebook, please email
                  DPO@corp.Showunited.com.
                </span>
              </p>
              <h3>
                <span>Google’s reCAPTCHA service</span>
              </h3>
              <p>
                <span>
                  To protect the input and comment areas of our App against spam
                  and abuse, we use the reCAPTCHA verification tool. This
                  service is provided by Google LLC, 1600 Amphitheatre Parkway,
                  Mountain View, CA 94043, USA and is subject to Google’s
                  privacy policy and{' '}
                </span>
                <a href='https://www.showunited.com/united-states-showunited-terms-and-conditions-of-use/'>
                  <span>Terms and Conditions of Use</span>
                </a>
                <span>..</span>
              </p>
              <p>
                <span>
                  reCAPTCHA makes it possible to differentiate between inputs of
                  human origin and those that are carried out improperly by a
                  bot. Certain device and application data will be transmitted
                  to Google’s servers in order to determine whether actions on
                  our App really come from humans. Google may also use this data
                  to improve the reCAPTCHA service. This data may include: URLs;
                  user IP address; Google account information if the user is
                  logged in to their Google account at the same time; browser
                  information, including browser type, browser size, browser
                  resolution, browser plug-ins, language settings and date;
                  mouse and touch events within the page; and information
                  collected from cookies. This processing is based on our
                  predominant legitimate interests in protecting our App from
                  abusive bots.
                </span>
              </p>
              <p>
                <span>
                  This data referred to above can include actions that you take
                  on our website such as your interactions with our website or
                  other information collected from cookies or similar
                  technologies including the Facebook pixel. This allows us to
                  measure the effectiveness of our advertising, improve our
                  marketing practices, and helps us deliver more relevant
                  advertising to you and people like you (including on social
                  media such as Facebook).
                </span>
              </p>
              <h2>
                <span>4. WHAT OTHERS MAY SEE ABOUT YOU</span>
              </h2>
              <p>
                <span>
                  We think our Users are awesome, and we want you to share how
                  awesome you are with the world, so we have built certain
                  features to enable this. Our App is designed to make it easier
                  for you to connect with other Users and to interact with them.
                </span>
              </p>
              <p>
                <span>
                  When using the Showunited App, you should assume that anything
                  you post or submit on the App may be publicly-viewable and
                  accessible, both by Users of the App and non-users of the App.
                  We want our Users to be careful about posting information that
                  may eventually be made public.
                </span>
              </p>
              <p>
                <span>
                  Please be careful about posting sensitive details about
                  yourself on your profile such as your religious denomination
                  and health details. While you may voluntarily provide this
                  information to us when you create your profile, including your
                  sexual preferences, there is no requirement to do so. Please
                  remember that photographs that you post on Showunited may
                  reveal information about yourself as well. Where you do upload
                  and choose to tell us sensitive information about yourself,
                  you are explicitly consenting to our processing of this
                  information and making it public to other Users.
                </span>
              </p>
              <p>
                <span>
                  Your Showunited profile and other information you make
                  available via the App, including certain information added to
                  your profile through third party accounts (such as Facebook or
                  Instagram – see section 7 of this Privacy Policy for more
                  information about this) may be viewed and shared by Users with
                  individuals who may or may not be Users of the App. For
                  example, a User may recommend you as a match to his or her
                  Facebook friend(s) by taking a screenshot of your profile
                  picture and sharing it, regardless of whether such friend(s)
                  is also a User of the App.
                </span>
              </p>
              <p>
                <span>
                  Please also note that in certain countries (currently, South
                  Korea only) Users are provided with functionality enabling
                  them to download a copy of their conversation history (as this
                  is required by local laws) and that Showunited has no control
                  over how Users may then publish or use that information.
                </span>
              </p>
              <p>
                <span>
                  If you log in to or access the App through your Facebook
                  account or another third-party account on a device which you
                  share with others, remember to log out of the App and the
                  relevant third-party account when you leave the device in
                  order to prevent other users from accessing your Showunited
                  account.
                </span>
              </p>
              <h2>
                <span>5. OUR POLICY TOWARDS AGE.</span>
              </h2>
              <p>
                <span>
                  Although we want as many people as possible to enjoy our
                  creation, you have to be at least 18 years old to use the App
                  &#8211; sorry kids, we know Showunited is cool, but you’ll
                  have to come back when you’re old enough!
                </span>
              </p>
              <p>
                <span>
                  Showunited does not knowingly collect any information about or
                  market to children, minors or anyone under the age of
                  majority. If we become aware that a child, minor or anyone
                  under the age of 18 has registered with us and provided us
                  with personal data, we will take steps to terminate that
                  person’s registration and delete their Profile information
                  from Showunited. If we do delete a Profile because you
                  violated our no children rules, we may retain your email and
                  IP address to ensure that you do not try to get around our
                  rules by creating a new Profile.
                </span>
              </p>
              <h2>
                <span>6. SECURITY.</span>
              </h2>
              <p>
                <span>
                  Showunited has implemented appropriate security measures to
                  protect and prevent the loss, misuse, unauthorised access or
                  disclosure and alteration of the information under our
                  control, including your personal data. We use reasonable
                  security measures to safeguard the confidentiality of your
                  personal information. Our technical experts at Showunited work
                  hard to ensure your secure use of our site.
                </span>
              </p>
              <p>
                <span>
                  Unfortunately, no website or Internet transmission is ever
                  completely 100% secure and even we cannot guarantee that
                  unauthorised access, hacking, data loss or other breaches will
                  never occur, but here are some handy tips to help keep your
                  data secure:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    Please make sure you log out of your Account after use as
                    you never know who may stumble onto your Account!
                  </span>
                </li>
                <li>
                  <span>
                    Please don’t share the password you use to access your
                    Showunited Account with anyone else!
                  </span>
                </li>
                <li>
                  <span>Change your password periodically.</span>
                </li>
              </ol>
              <h2>
                <span>7. LINKING OTHER ACCOUNTS TO Showunited.</span>
              </h2>
              <h3>
                <span>
                  Using your social media details to sign in to Showunited
                </span>
              </h3>
              <p>
                <span>
                  When you sign in to our App using your Facebook account, you
                  give permission to Facebook to share with us your name and
                  profile picture. Unless you opt-out, you also give permission
                  to Facebook to share with us your email address (if there is
                  one associated with your Facebook account), date of birth,
                  profile photos, gender, Page likes and current town/city.
                </span>
              </p>
              <p>
                <span>
                  If you register or sign in with your Apple ID, you give Apple
                  permission to share your Apple login, a name (that can be
                  edited by you) and an email (you can choose to hide your email
                  and Apple will create a random email address so your personal
                  email can stay private). This email address will be linked to
                  your Showunited account and will be used to retrieve your
                  Showunited account.
                </span>
              </p>
              <p>
                <span>
                  We will then use this personal data to form your Showunited
                  account. If you remove the Showunited app from your Facebook
                  settings, or from your Apple ID, we will no longer have access
                  to this data. However, we will still have the personal data
                  that we received when you first set up your Showunited account
                  using your Facebook or Apple ID (you must delete your
                  Showunited account entirely for us to no longer have access to
                  this data).
                </span>
              </p>
              <h3>
                <span>
                  Linking social media accounts to your Showunited account
                </span>
              </h3>
              <p>
                <span>
                  You may link your Showunited account with your Instagram or
                  Facebook accounts. This allows you to share some information
                  from those accounts directly to your Showunited account (for
                  example, Instagram photos).
                </span>
              </p>
              <p>
                <span>
                  We only receive the limited information that
                  Instagram/Facebook permits to be transferred (as detailed by
                  Instagram/Facebook and agreed by you when you first connect
                  your account from such platforms with your Showunited
                  account).
                </span>
              </p>
              <p>
                <span>
                  If you no longer want to link your Showunited account to your
                  Instagram or Facebook account, please visit the settings in
                  your Instagram or Facebook account and follow the instructions
                  to remove the Showunited app access permissions. Please note
                  that any information already added to your Showunited account
                  from those platforms will not be deleted unless you delete it
                  within your Showunited account as well.
                </span>
              </p>
              <h2>
                <span>8. YOUR CALIFORNIA PRIVACY RIGHTS</span>
              </h2>
              <p>
                <span>
                  For Users who are California residents, you have the following
                  rights (in addition to those listed at section 9 below) under
                  the California Consumer Privacy Act, and you have the right to
                  be free from unlawful discrimination for exercising your
                  rights under the Act:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    You have the right to request that we disclose certain
                    information to you and explain how we have collected, used
                    and shared your personal information over the past 12
                    months.
                  </span>
                </li>
                <li>
                  <span>
                    You have the right to request that we delete your personal
                    information that we collected from you, subject to certain
                    exceptions.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  California’s “Shine the Light” law, Civil Code section
                  1798.83, requires certain businesses to respond to requests
                  from California customers asking about the businesses’
                  practices related to disclosing personal information to third
                  parties for the third parties’ direct marketing purposes. If
                  you wish to find out about any rights you may have under
                  California Civil Code section 1798.83, you can write to us at{' '}
                </span>
                <a href='mailto:dpo@showunited.com'>
                  <span>dpo@showunited.com</span>
                </a>
                <span>.</span>
              </p>
              <p>
                <span>
                  From time to time, as part of a joint promotion with a third
                  party, we may, if you participate in such a promotion,
                  disclose your contact information to the third party to allow
                  them to market their products or services to you. Where this
                  is a condition for participation in a promotion, we will
                  always let you know before you enter the promotion. Please
                  follow the instructions provided to you by third parties to
                  unsubscribe from their messages.
                </span>
              </p>
              <p>
                <span>
                  In addition, Under California law, operators of online
                  services are required to disclose how they respond to “do not
                  track” signals or other similar mechanisms that provide
                  consumers the ability to exercise choice regarding the
                  collection of personal information of a consumer over time and
                  across third party online services, to the extent the operator
                  engages in that collection. At this time, we do not track our
                  Users’ personal information over time and across third-party
                  online services. This law also requires operators of online
                  services to disclose whether third parties may collect
                  personal information about their users’ online activities over
                  time and across different online services when the users use
                  the operator’s service. We do not knowingly permit third
                  parties to collect personal information about an individual
                  User’s online activities over time and across different online
                  services when using the App.
                </span>
              </p>
              <h2>
                <span>9. YOUR RIGHTS.</span>
              </h2>
              <p>
                <span>
                  Privacy laws applicable in your country may give you the
                  following rights:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    Right to be informed: what personal data an organisation is
                    processing and why (this notice).
                  </span>
                </li>
                <li>
                  <span>
                    Right of access: you can request a copy of your data.
                  </span>
                </li>
                <li>
                  <span>
                    Right of rectification: if the data held is inaccurate, you
                    have the right to have it corrected.
                  </span>
                </li>
                <li>
                  <span>
                    Right to erasure: you have the right to have your data
                    deleted in certain circumstances.
                  </span>
                </li>
                <li>
                  <span>
                    Right to restrict processing: in limited circumstances, you
                    have the right to request that processing is stopped but the
                    data retained.
                  </span>
                </li>
                <li>
                  <span>
                    Right to data portability: you can request a copy of your
                    data in a machine-readable form that can be transferred to
                    another provider.
                  </span>
                </li>
                <li>
                  <span>
                    Right to object: in certain circumstances (including where
                    data is processed on the basis of legitimate interests or
                    for the purposes of marketing) you may object to that
                    processing.
                  </span>
                </li>
                <li>
                  <span>
                    Rights related to automated decision-making including
                    profiling: there are several rights in this area where
                    processing carried out on a solely automated basis results
                    in a decision which has legal or significant effects for the
                    individual. In these circumstances your rights include the
                    right to ensure that there is human intervention in the
                    decision-making process.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  The particular rights which are applicable to you (which might
                  include other rights not listed above) may vary depending on
                  your country. You should make yourself aware of the rights you
                  have under applicable privacy laws in your country.
                </span>
              </p>
              <p>
                <span>
                  If you want to exercise any of your rights listed above please
                  visit our Website or email our Data Protection Officer at
                  DPO@corp.Showunited.com. You can also contact us by post at
                  the following address: The Broadgate Tower Third Floor, 20
                  Primrose Street, London, United Kingdom, EC2A 2RS. For your
                  protection and the protection of all of our Users, we may need
                  to request specific information from you to help us confirm
                  your identity before we can answer the above requests.
                </span>
              </p>
              <p>
                <span>
                  If you have a concern about how we have processed your request
                  or your personal data, you should contact us in the first
                  instance via the contact details listed above.
                </span>
              </p>
              <p>
                <span>
                  If you feel we have not resolved your concern, you have the
                  right to make a complaint at any time to the Information
                  Commissioner’s Office (ICO), the UK supervisory authority for
                  data protection issues (
                </span>
                <a href='http://www.ico.org.uk/'>
                  <span>www.ico.org.uk</span>
                </a>
                <span>
                  ). If you live in a country or territory located in the
                  European Union (EU) or European Economic Area (EEA), you may
                  also get in touch with your local{' '}
                </span>
                <a href='https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080'>
                  <span>Data Protection Regulator</span>
                </a>
                <span>
                  . If you live in a country outside the EU, you may have the
                  right to lodge a complaint with your local privacy or data
                  protection regulator.
                </span>
              </p>
              <h3>
                <span>EU Representative</span>
              </h3>
              <p>
                <span>
                  Pursuant to Article 27 of the General Data Protection
                  Regulation (GDPR), Showunited has appointed Borlux Ltd as its
                  GDPR representative in the EU. If you usually reside in an EU
                  Member State you can contact Borlux Ltd regarding matters
                  pertaining to the GDPR by:
                </span>
              </p>
              <p>
                <span>
                  Using the online request form at &#8211;
                  https://services.nathantrust.com/privacycontact
                </span>
              </p>
              <p>
                <span>
                  Postal address: 1st Floor, 6 Lapps Quay, Cork, Ireland
                </span>
              </p>
              <h2>
                <span>10. DATA LOCATIONS.</span>
              </h2>
              <p>
                <span>
                  We want you to be able to access Showunited wherever you
                  happen to be in the world. To enable us to provide that
                  service, we operate a global network of servers including in
                  the US, UK, EU, and (for Users located in Russia) Russia. The
                  hardware is located in third-party data centres but is owned
                  by the Showunited Group. Data collected by Advertising
                  Partners and other Service Providers may also be held outside
                  the UK and the European Economic Area. We ensure that the data
                  is adequately protected by ensuring that valid, legal
                  mechanisms are in place such as: EU approved model clauses
                  (which can be found{' '}
                </span>
                <a href='https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en'>
                  <span>here</span>
                </a>
                <span>
                  ), and implementing robust contractual standards. If you want
                  more information relating to the nature of the safeguards we
                  have in place, please email DPO@corp.Showunited.com.
                </span>
              </p>
              <h2>
                <span>11. DATA RETENTION AND DELETION.</span>
              </h2>
              <p>
                <span>
                  We keep your personal information only as long as we need it
                  for the legal basis relied upon (as set out in Section 2
                  above) and as permitted by applicable law.
                </span>
              </p>
              <p>
                <span>
                  You may permanently delete your Account at any time (although
                  we really hope you don’t!)
                </span>
              </p>
              <p>
                <span>
                  When your Account is deactivated, we take reasonable efforts
                  to make sure it is no longer viewable on the App. For up to 28
                  days it is still possible to restore your Account if it was
                  accidentally or wrongfully deactivated. After 28 days, we
                  begin the process of deleting your personal information from
                  our systems, unless:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    we must keep it to comply with applicable law (for instance,
                    if you make purchases within the App, some personal data may
                    need to be kept for accounting purposes);
                  </span>
                </li>
                <li>
                  <span>
                    we must keep it to evidence our compliance with applicable
                    law;
                  </span>
                </li>
                <li>
                  <span>
                    there is an outstanding issue, claim or dispute requiring us
                    to keep the relevant information until it is resolved; or
                  </span>
                </li>
                <li>
                  <span>
                    the information must be kept for our legitimate business
                    interests, such as fraud prevention and enhancing Users’
                    safety and security. For example, information may need to be
                    kept to prevent a user who was banned for unsafe behaviour
                    or security incidents from opening a new account.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  Where Showunited uses machine learning, for example, to help
                  us detect and prevent fraudulent card transactions, and to
                  detect and prevent spam communications on the App (as
                  explained above), we may need to keep personal information for
                  a longer period than the retention periods explained above, to
                  enable the machine learning to work properly. Where this is
                  the case, we always seek to minimise the amount of personal
                  information that is used and we ensure that it is ring-fenced
                  and kept securely from other user personal information. We
                  regularly review the period for which personal information is
                  required for machine learning purposes and delete any
                  identifiable information when it is no longer required.
                </span>
              </p>
              <p>
                <span>
                  To prevent abuse and/or misuse of Showunited by a User
                  following termination or deletion of a profile/Account we
                  shall retain such information as we deem in our sole
                  discretion may be necessary to ensure that a User does not
                  open a new Account and profile in breach of our Terms and
                  Conditions of Use and to ensure compliance with all laws and
                  regulations.
                </span>
              </p>
              <p>
                <span>
                  Warning: Even after you remove information from your profile
                  or delete your Account, copies of that information may still
                  be viewable and/or accessed to the extent such information has
                  been previously shared with others, or copied or stored by
                  others. We cannot control this, nor do we accept any liability
                  for this. If you have given third party applications or
                  websites access to your personal information, they may retain
                  such information to the extent permitted under their terms of
                  service or privacy policies.
                </span>
              </p>
              <p>
                <span>
                  Thanks for reading, we hope we didn’t drone on for too long!
                </span>
              </p>
              <h2>
                <span>12. CHANGES TO THIS POLICY.</span>
              </h2>
              <p>
                <span>
                  As Showunited evolves, we may revise this Privacy Policy from
                  time to time. The most current version of the policy will
                  govern our use of your information and will always be at
                  https://Showunited.com/privacy/. If we make a change to this
                  policy that, in our sole discretion, is material, we will
                  notify you, for example, via an email to the email associated
                  with your Account or by posting a notice within Showunited.
                </span>
              </p>
              <h2>
                <span>About us</span>
              </h2>
              <p>
                <span>
                  www.showunited.com is an app and site owned and operated by
                  showunited SL.
                </span>
              </p>
              <p>
                <span>
                  We are registered in Spain under NIF number 14305036w.
                </span>
              </p>
              <p>
                <span>
                  Our registered office is at Innovem, Carrer Punta Nati 8,
                  Office 1 &#8211; 07714 Mahon &#8211; SPAIN
                </span>
              </p>
              <h2>
                <span>Effective date</span>
              </h2>
              <p>
                <span>The Terms were last updated on: Nov 10, 2021.</span>
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </Col>
        </Row>
      </div>
      <Footer profileId={data.me.id} />
    </div>
  );
};

export default PrivacyPolicy;
