import React from 'react';
import Images from './components/Images';
import { Col } from 'react-bootstrap';

interface AboutProps {
  about: string;
  images: string[];
}

const About: React.FC<AboutProps> = ({ about, images }) => {
  return(
    <>
      <Images images={images} />
      <Col className="mt-4">
        <h4>About</h4>
        <p>{about}</p>
      </Col>
    </>
  );
};
export default About;


