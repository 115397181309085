import React from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'ui/MyProfile/Login/images/logo-white.svg';
import PrimaryButton from '../../components/form/PrimaryButton';
import FormInput from '../../components/form/FormInput';
import './style.css';

interface ResetPasswordUIProps {
  email: string,
  password: string,
  passwordError: string;
  confirmPassword: string,
  confirmPasswordError: string;
  handlePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickReset: any;
  isLoading: boolean;
  serverError: string;
  resetSuccess: boolean;
}

const ResetPasswordUI: React.FC<ResetPasswordUIProps> = ({ email, password, passwordError, confirmPassword, confirmPasswordError, handlePassword, handleConfirmPassword, onClickReset, isLoading, serverError, resetSuccess }) => {
  return(
    <div className="container-login">
      <Row>
        <Col className="text-center mt-md-5">
          <img alt="logo" src={logo} className="logo" />
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-md-3 mb-mb-5 login-subtitle">
          Enter here your password.<br/> You will receive an email with reset instructions.
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login">
            <FormInput type="text" value={email} placeholder="Enter email" disabled />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login">
            <FormInput type="password" value={password} onChange={handlePassword} placeholder="Enter new password" error={passwordError}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login">
            <FormInput type="password" value={confirmPassword} onChange={handleConfirmPassword} placeholder="Confirm new password" error={confirmPasswordError}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <div className="content-login mt-2">
            <PrimaryButton label="Reset" loading={isLoading} loadingMessage="Reseting email..." onClick={onClickReset} style="mt-5" />
            {serverError && <div className="login-server-error">{serverError}</div>}
            {resetSuccess && <div className="reset-success-message">The password has been reseted successfully. You will be redirected to login in a few seconds...</div>}
          </div>
        </Col>
      </Row>
    </div>
  );
};


export default ResetPasswordUI;
