import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import LineIcon from 'ui/theme/icons/LineIcon';
import MenuItem from './components/MenuItem';
import './style.css';

const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      {/*<MenuItem label='Edit MyProfile' icon='user-edit' size={18} link="/logout"  />*/}
      {/*<div className='menu-divider' />*/}
      {/*<MenuItem label='Favourites' icon='heart-filled' size={15} link="/logout"  />*/}
      {/*<div className='menu-divider' />*/}
      <MenuItem label='Logout' icon='power-switch' size={15} link="/logout" />
    </Popover.Body>
  </Popover>
);

const SearchBar: React.FC = () => {
  const isMobile = window.innerWidth < 400;
  return(
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
      <Button className="icon-container p-0 menu-icon"><LineIcon name="menu" size={isMobile ? 28 : 34} /></Button>
    </OverlayTrigger>
  );
};
export default SearchBar;
