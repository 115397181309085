import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { QUERY_All_USER } from 'infrastructure/graphql/query';
import Loading from 'ui/components/Loading';
import SearchItem from './components/SearchItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.css';

let newCursor: string = '';

const popover = (data: Record<string, any>, loading: boolean, updateSearchResultList: any) => (
  <Popover id="popover-search">
    <Popover.Body>
      {loading && <Loading /> }
      {!loading && <InfiniteScroll
        dataLength={data?.allUsers.edges.length} //This is important field to render the next data
        next={updateSearchResultList}
        hasMore={true}
        loader={<h6>Loading...</h6>}
        endMessage={<h6>Search complete.</h6>}
      >
        {data?.allUsers.edges?.map((user: Record<string, any>, index: number) => <SearchItem key={index} user={user.node} /> ) }
      </InfiniteScroll>}
      {data?.allUsers.edges?.length === 0 && <div className="search-bar-empty-message">No hay resultados para esta búsqueda</div> }
    </Popover.Body>
  </Popover>
);

const SearchBar: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const { data, loading, fetchMore, refetch } = useQuery(QUERY_All_USER, { variables: { search: searchText, cursor: '' } });
  // @ts-ignore
  useEffect(() => refetch(), []);

  const updateSearchResultList = (searchText: string) => {
    fetchMore({
      variables: {
        search: searchText,
        cursor: newCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newResults = fetchMoreResult?.allUsers;
        const pageInfo = newResults.pageInfo;
        const newEdges = newResults.edges;
        newCursor = newResults.pageInfo.endCursor;
        if (!fetchMoreResult) return previousResult;
        if (!previousResult.allUsers) return previousResult;
        return {
          allUsers: {
            __typename: previousResult?.allUsers.__typename,
            edges: [...previousResult.allUsers.edges, ...newEdges],
            pageInfo
          }
        };
      }
    });
  };

  const searchUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (searchText.length > 2 || searchText == '') {
      newCursor = '';
      updateSearchResultList(searchText);
    }
  };

  return(
    <>
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover(data, loading, updateSearchResultList)} rootClose>
        <input type="text" placeholder="&#61442; Search" value={searchText}  className="input-search" onChange={searchUsers} />
      </OverlayTrigger>
    </>
  );
};
export default SearchBar;
